import React, { useState, useRef, useMemo, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import { AiFillDelete } from 'react-icons/ai';

function Payslip() {
    const id = Cookies.get("id")
    const token = Cookies.get("Token")
    const email = Cookies.get("email")
    const name = Cookies.get("Name")
    const [popup, setpopup] = useState(false)
    const [popup2, setpopup2] = useState("assign")
    const [staffprofiles, setstaffprofiles] = useState([])
    const [salary, setsalary] = useState([])
    const [Year, setYear] = useState()
    const [Month, setMonth] = useState()
    const [assign, setassign] = useState([])
    const [BASICSALARY, setBASICSALARY] = useState(0)
    const [LessSSF, setLessSSF] = useState(0)
    const [TransportAllowance, setTransportAllowance] = useState(0)
    const [RentAllowance, setRentAllowance] = useState(0)
    const [FuelAllowance, setFuelAllowance] = useState(0)
    const [TaxRelief, setTaxRelief] = useState(0)
    const [IncomeTax, setIncomeTax] = useState(0)
    const [SundrayLoans, setSundrayLoans] = useState(0)
    const [SalaryAdvance, setSalaryAdvance] = useState(0)
    const [PF, setPF] = useState(0)
    const [GrossSalary, setGrossSalary] = useState(0)
    const [SubTotal, setSubTotal] = useState(0)
    const [NETPAY, setNETPAY] = useState(0)
    useEffect(() => {




        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'GET',

            redirect: 'follow'
        };

        fetch("https://backend.datawarehousegh.com:1337/getstaffprofiles", requestOptions)
            .then(response => response.json())
            .then(result => setstaffprofiles(result))
            .catch(error => console.log('error', error));




        // fetch("https://backend.datawarehousegh.com:1337/getCommissions", requestOptions)
        //   .then(response => response.json())
        //   .then(result => setCommissions(result))
        //   .catch(error => console.log('error', error));

        fetch("https://backend.datawarehousegh.com:1337/getsalary", requestOptions)
            .then(response => response.json())
            .then(result => setsalary(result))
            .catch(error => console.log('error', error));





        const today = new Date();
        const year = today.getFullYear();
        const month = today.toLocaleString('default', { month: 'long' }); // Get the full month name

        setYear(year);
        setMonth(month);







    }, [])

    useEffect(() => {

        const gross = parseInt(BASICSALARY) + parseInt(TransportAllowance) + parseInt(RentAllowance) + parseInt(FuelAllowance) - parseInt(LessSSF)
        setGrossSalary(gross)

        const sub = parseInt(TaxRelief) + parseInt(IncomeTax) + parseInt(SundrayLoans) + parseInt(SalaryAdvance) + parseInt(PF)
        setSubTotal(sub)

        setNETPAY(gross - sub)

    }, [
        BASICSALARY,
        LessSSF,
        TransportAllowance,
        RentAllowance,
        FuelAllowance,
        TaxRelief,
        IncomeTax,
        SundrayLoans,
        SalaryAdvance,
        PF,
        GrossSalary,
        SubTotal,
        NETPAY,
    ])

    const currentYear = new Date().getFullYear();

    // Define the range of years (e.g., from current year to 10 years in the past)
    const startYear = currentYear - 10;
    const endYear = currentYear;

    // Array to store options for years
    const yearOptions = [];

    // Loop to generate options for each year in the range
    for (let year = startYear; year <= endYear; year++) {
        yearOptions.push(
            <option key={year} value={year}>
                {year}
            </option>
        );
    }

    return (
        <div>
            {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>


                <Navbar />
                <div className='mainbody'>
                    <Sidemanu />
                    <div className='dashbordbody'>
                        <div className='header'>
                            <h1 className='pppheading'> PaySlips</h1>


                        </div>
                        <div className='Tableheader'><h3></h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>
                        <div className='Tableheader'><h3>******* Department</h3> <div className="calendar-controls">


                            <select id="year" name="year" value={Year} onChange={(e) => setYear(parseInt(e.target.value))}>
                                <option disabled value="">
                                    Year
                                </option>
                                {yearOptions}
                            </select>

                            <select id="month" name="month" value={Month} onChange={(e) => setMonth(e.target.value)}>
                                <option disabled value="">
                                    Month
                                </option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </select>


                        </div></div>

                        {salary.length !== 0 && <>
                            <table className='tablep'>
                                <thead className='tablephead'>
                                    <tr>
                                        <th>S NO.</th>

                                        <th>Employee name</th>
                                        <th>Employee Email</th>
                                        <th>BASIC SALARY</th>
                                        <th>Transport Allowance</th>
                                        <th>Rent  Allowance</th>
                                        <th>Fuel Allowance</th>

                                        <th>Income Tax</th>
                                        <th>Salary Advance</th>
                                        <th>Gross Salary</th>
                                        <th>Sub Total   </th>

                                        <th>NET PAY </th>
                                        <th></th>


                                    </tr>
                                </thead>
                                <tbody>

                                    {salary.map((value, index) => {

                                        var email
                                        var name

                                        staffprofiles.map((item) => {
                                            if (value.staffID === item._id) {
                                                email = item.email
                                                name = item.FullName
                                            }
                                        })
                                        return (<>
                                            {

                                                (parseInt(value.Year) === parseInt(Year) && value.Month === Month ) && <>
                                                    <tr>

                                                        <td>{index + 1}</td>
                                                        <td>{name}</td>
                                                        <td>{email}</td>
                                                        <td>{value.BASICSALARY}</td>
                                                        <td>{value.TransportAllowance}</td>
                                                        <td>{value.RentAllowance}</td>
                                                        <td>{value.FuelAllowance}</td>
                                                        <td>{value.IncomeTax}</td>
                                                        <td>{value.SalaryAdvance}</td>
                                                        <td>{value.GrossSalary}</td>
                                                        <td>{value.SubTotal}</td>
                                                        <td>{value.NETPAY}</td>
                                                        <td>
                                                            <AiFillDelete className='hovar' onClick={async () => {

                                                                var myHeaders = new Headers();
                                                                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                                                var urlencoded = new URLSearchParams();
                                                                urlencoded.append("_id", value._id);


                                                                var requestOptions = {
                                                                    method: 'POST',
                                                                    headers: myHeaders,
                                                                    body: urlencoded,
                                                                    redirect: 'follow'
                                                                };

                                                                await fetch("https://backend.datawarehousegh.com:1337/dtlSalary", requestOptions)
                                                                    .then(response => response.json())
                                                                    .then(result => {
                                                                        setsalary(result)



                                                                    })
                                                                    .catch(error => console.log('error', error));


                                                            }} style={{ color: "red", width: '25px', height: '25px' }} />
                                                        </td>


                                                    </tr>
                                                </>
                                            }
                                        </>)
                                    })}

                                </tbody>
                            </table>
                        </>}
                    </div>









                    {popup === true && <>
                        <div className='popup'>
                            <div className='popupbg' onClick={() => setpopup(false)}></div>
                            <div className='popupcontant'>



                                {popup2 === "assign" && <>


                                    <div className='customerassignbox'>


                                        {staffprofiles.map((value) => <>
                                            <div className='customerassigncard' onClick={() => {

                                                setassign(value)
                                                setpopup2("payslip")
                                            }}>
                                                <h3>Name : {value.FullName}</h3>
                                                <h3>Email : {value.email}</h3>
                                                <h3>ID Number : {value.IDNumber}</h3>
                                            </div>
                                        </>)}
                                    </div>
                                </>}

                                {popup2 === "payslip" && <>
                                    <h2>Enter the Details Below</h2>


                                    <form className='newenteryform2' onSubmit={async (e) => {
                                        e.preventDefault()


                                        const myHeaders = new Headers();
                                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                                        const urlencoded = new URLSearchParams();
                                        urlencoded.append("staffID", assign._id);
                                        urlencoded.append("Year", Year);
                                        urlencoded.append("Month", Month);

                                        urlencoded.append("BASICSALARY", BASICSALARY);
                                        urlencoded.append("LessSSF", LessSSF);
                                        urlencoded.append("TransportAllowance", TransportAllowance);
                                        urlencoded.append("RentAllowance", RentAllowance);
                                        urlencoded.append("FuelAllowance", FuelAllowance);
                                        urlencoded.append("TaxRelief", TaxRelief);
                                        urlencoded.append("IncomeTax", IncomeTax);
                                        urlencoded.append("SundrayLoans", SundrayLoans);
                                        urlencoded.append("SalaryAdvance", SalaryAdvance);
                                        urlencoded.append("PF", PF);
                                        urlencoded.append("GrossSalary", GrossSalary);
                                        urlencoded.append("SubTotal", SubTotal);
                                        urlencoded.append("NETPAY", NETPAY);



                                        const requestOptions = {
                                            method: "POST",
                                            headers: myHeaders,
                                            body: urlencoded,
                                            redirect: "follow"
                                        };

                                        await fetch("https://backend.datawarehousegh.com:1337/addsalary", requestOptions)
                                            .then((response) => response.json())
                                            .then((result) => {
                                                setsalary(result)
                                                setpopup2("assign")
                                                setpopup(false)
                                            })
                                            .catch((error) => console.error(error));
                                    }} >

                                        <div className='insideform newenteryform2div'><label>BASIC SALARY                  </label> <input type='number' required onChange={(e) => setBASICSALARY(e.target.value)} value={BASICSALARY} /></div>

                                        <div className='insideform newenteryform2div'><label>Less 5.5%  S.S.F                  </label> <input type='number' required onChange={(e) => setLessSSF(e.target.value)} value={LessSSF} /></div>

                                        <div className='insideform newenteryform2div'><label>Transport Allowance                  </label> <input type='number' required onChange={(e) => setTransportAllowance(e.target.value)} value={TransportAllowance} /></div>

                                        <div className='insideform newenteryform2div'><label>Rent  Allowance                  </label> <input type='number' required onChange={(e) => setRentAllowance(e.target.value)} value={RentAllowance} /></div>

                                        <div className='insideform newenteryform2div'><label>Fuel Allowance                  </label> <input type='number' required onChange={(e) => setFuelAllowance(e.target.value)} value={FuelAllowance} /></div>


                                        <h3>DEDUCTIONS:</h3>
                                        <div className='insideform newenteryform2div'><label>Tax Relief</label> <input type='number' required onChange={(e) => setTaxRelief(e.target.value)} value={TaxRelief} /></div>
                                        <div className='insideform newenteryform2div'><label>Income Tax</label> <input type='number' required onChange={(e) => setIncomeTax(e.target.value)} value={IncomeTax} /></div>
                                        <div className='insideform newenteryform2div'><label>Sundray Loans  </label> <input type='number' required onChange={(e) => setSundrayLoans(e.target.value)} value={SundrayLoans} /></div>
                                        <div className='insideform newenteryform2div'><label>Salary Advance   </label> <input type='number' required onChange={(e) => setSalaryAdvance(e.target.value)} value={SalaryAdvance} /></div>
                                        <div className='insideform newenteryform2div'><label>5% P.F  </label> <input type='number' required onChange={(e) => setPF(e.target.value)} value={PF} /></div>

                                        <div className='insideform newenteryform2div'><label>Gross Salary    </label> <input type='number' required value={GrossSalary} /><label>Sub Total  </label> <input type='number' required value={SubTotal} /></div>


                                        <div className='insideform newenteryform2div'><label>  <h3>NET PAY:</h3> </label> <input type='number' required value={NETPAY} /></div>


                                        <button style={{ width: '100%', margin: '0' }} className="formalbtn">Post</button>

                                    </form>

                                </>}

                            </div>
                        </div>
                    </>}











                </div></>}
        </div>
    )
}

export default Payslip
