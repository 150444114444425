import React from 'react'
import "./css/navbar.css"
import Cookies from 'js-cookie';
import { NavLink, useNavigate } from 'react-router-dom';







function Navbar() {
  let navigate = useNavigate();











  return (
    <>
      <nav className='navbar'>

        <div className='navlogo' st>
          <img src={"logo192.png"} width={'100%'} />


        </div>
        <div className='logoutandsearch'>
          <form>
            <input placeholder='search' />

          </form>

          <button onClick={() => {

            const allCookies = Cookies.get();
            for (const cookieName in allCookies) {
              Cookies.remove(cookieName);
            }
            navigate('/')


          }}>Logout</button>
        </div>

      </nav>


    </>

  )
}

export default Navbar