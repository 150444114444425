import React, { useState, useEffect } from 'react'
import "./Css/staffprofiles.css"
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate } from 'react-router-dom'
import Password from 'antd/es/input/Password'
import Cookies from 'js-cookie';
import { stringify } from 'uuid'


function PayslipSalarySystem() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  // const token = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
  const loginemail = Cookies.get("email")


  const Navigate = useNavigate()
  const [viewdetails, setviewdetails] = useState(false)


  const [Year, setYear] = useState()
  const [Month, setMonth] = useState()

  const [Commissions, setCommissions] = useState([])
  const [salaryslippp, setsalaryslippp] = useState([])
  const [mainprofile, setmainprofile] = useState(null)




  const [staffprofiles, setstaffprofiles] = useState([])







  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getstaffprofiles", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));




    fetch("https://backend.datawarehousegh.com:1337/getCommissions", requestOptions)
      .then(response => response.json())
      .then(result => setCommissions(result))
      .catch(error => console.log('error', error));

    fetch("https://backend.datawarehousegh.com:1337/getsalary", requestOptions)
      .then(response => response.json())
      .then(result => setsalaryslippp(result))
      .catch(error => console.log('error', error));






    const today = new Date();
    const year = today.getFullYear();
    const month = today.toLocaleString('default', { month: 'long' }); // Get the full month name

    setYear(year);
    setMonth(month);





  }, [])

  const currentYear = new Date().getFullYear();

  // Define the range of years (e.g., from current year to 10 years in the past)
  const startYear = currentYear - 10;
  const endYear = currentYear;

  // Array to store options for years
  const yearOptions = [];

  // Loop to generate options for each year in the range
  for (let year = startYear; year <= endYear; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }



  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>



            <div className='header'>
              <h1 className='pppheading'> Payslip and Salary System</h1>


            </div>
            {viewdetails === false && <>

              <div className='Tableheader'><h3>******* Department</h3> <div className="calendar-controls">


                <select id="year" name="year" value={Year} onChange={(e) => setYear(parseInt(e.target.value))}>
                  <option disabled value="">
                    Year
                  </option>
                  {yearOptions}
                </select>

                <select id="month" name="month" value={Month} onChange={(e) => setMonth(e.target.value)}>
                  <option disabled value="">
                    Month
                  </option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>


              </div></div>

              {staffprofiles.length !== 0 && <>
                <table className='tablep'>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>

                      <th>Name</th>

                      <th>ID Number</th>
                      <th>Email</th>


                      <th>Basis Salary</th>
                      <th>Year</th>
                      <th>Month</th>
                      <th>Status</th>
                      <th>Option</th>


                    </tr>
                  </thead>
                  <tbody>

                    {staffprofiles.map((value, index) => {
                      var Commission = 0
                      var allowance = 0

                      var statuuss = true



                      salaryslippp.map((value2) => {

                        if (value2.staffID === value._id && parseInt(value2.Year) === Year && value2.Month === Month) {
                          statuuss = false
                        }


                      })





                      Commissions.map((value2) => {

                        if (value2.staffID === value._id && value2.status === false) {
                          Commission = parseInt(Commission) + parseInt(value2.Ammount)
                          console.log(value2.status)
                        }


                      })
                      var total = Commission + allowance + parseInt(value.Salary);

                      return (

                        <tr>

                          <td>{index + 1}</td>

                          <td>{value.FullName}</td>
                          <td>{value.IDNumber}</td>
                          <td>{value.email}</td>
                          <td>{value.Salary}<br /> </td>
                          {/* <td>{value.}</td> */}
                          <td>{Year}</td>
                          <td>{Month}</td>
                          {statuuss ? (<>
                            <td>

                              <a href='' onClick={async (e) => {




                                e.preventDefault()


                                Navigate("/payslip")






                              }}> Pay</a>
                            </td>
                          </>) : (<>
                            <td>Paid</td>
                          </>)}
                          <td style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <button className='formalbtn' onClick={() => {
                              setviewdetails(true)
                              setmainprofile(value)

                            }}>View Records </button>
                             {statuuss ? (<></>):(<>
                            <button className='formalbtn' style={{ backgroundColor: "green" }}>Payslip </button></>)}
                          </td>




                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </>}
            </>}


            {viewdetails === true && <>



              <div style={{ display: 'flex', width: '100%', height: "fit-contant", gap: '10px' }}>
                <div className='profilepix' style={{ width: '40%' }} >
                  <img alt='' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY022kOu3BE3CXHtOafMV2bG3T9nUI-fsYJVpKSRCPfQ&s' />
                </div>
                <div style={{ width: '60%' }}>

                  <div className='infodivvv'> <h3>Full Name :</h3> <h3> &nbsp;{mainprofile.FullName}</h3> </div>
                  <div className='infodivvv'> <h3>Date Of Birth :</h3> <h3> &nbsp;{mainprofile.DateOfBirth}</h3> </div>
                  <div className='infodivvv'> <h3>Address :</h3> <h3> &nbsp;{mainprofile.Address}</h3> </div>
                  <div className='infodivvv'> <h3>Contact Details :</h3> <h3> &nbsp;{mainprofile.ContactDetails}</h3> </div>
                  <div className='infodivvv'> <h3>Next Of Kin Name and Contact Number :</h3> <h3> &nbsp;{mainprofile.NextOfKinNameandContactNumber}</h3> </div>
                  <div className='infodivvv'> <h3>Socail Security Number :</h3> <h3> &nbsp;{mainprofile.SocailSecurityNumber}</h3> </div>
                  <div className='infodivvv'> <h3>ID Number :</h3> <h3> &nbsp;{mainprofile.IDNumber}</h3> </div>
                  <div className='infodivvv'> <h3>Emergancy Contact Number And Name :</h3> <h3> &nbsp;{mainprofile.EmergancyContactNumberAndName}</h3> </div>
                  <div className='infodivvv'> <h2>Basics Salary :</h2> <h2> &nbsp;{mainprofile.Salary}</h2> </div>


                </div>



              </div>


              <h2 className='hovar' onClick={() => setviewdetails(false)} style={{ marginTop: '100px' }}>Go Back</h2>


              {salaryslippp.map((value) => {

                var CustomerName
                var Email
                var ID
                var Address




                staffprofiles.map((value2) => {

                  if (value2._id === value.staffID) {

                    CustomerName = value2.FullName
                    Email = value2.email
                    ID = value2.IDNumber
                    Address = value2.Address

                  }

                })



                return (<>
                  <div>

                    {mainprofile._id === value.staffID && <>
                      <div className='invoicebox'>
                        <div className='invoiceinside'>
                          <div>
                            <h3>{CustomerName}</h3>

                            <p>{Email}</p>
                            <p>{ID}</p>
                            <p>{Address}</p>
                            <p>{Email}</p>
                          </div>


                          <div>

                            <h3>when</h3>
                            <p>{value.Year}</p>

                            <p>{value.Month}</p>


                          </div>

                          <div>

                            <h3>Summary </h3>

                            <p>  BASIC SALARY : {value.BASICSALARY} </p>
                            <p>  Less 5.5%  S.S.F : {value.LessSSF} </p>
                            <p>  Transport Allowance : {value.TransportAllowance} </p>
                            <p>  Rent  Allowance : {value.RentAllowance} </p>
                            <p>  Fuel Allowance : {value.FuelAllowance} </p>
                            <p> <strong> Gross Salary :</strong> {value.GrossSalary} </p>



                            <p> <br></br>  </p>

                            <p> <strong> DEDUCTIONS</strong></p>
                            <p>  Tax Relief : {value.TaxRelief} </p>
                            <p>  Income Tax : {value.IncomeTax} </p>
                            <p>  Sundray Loans : {value.SundrayLoans} </p>
                            <p>  Salary Advance : {value.SalaryAdvance} </p>
                            <p>  5% P.F : {value.PF} </p>
                            <p> <strong> Sub Total  :</strong> {value.SubTotal} </p>


                            <p> <strong> NET PAY :</strong> {value.NETPAY} </p>


                          </div>
                        </div>
                        <div>
                          <h3> Date: {value.timestamp}</h3>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <button className='formalbtncncl' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtlSalary", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setsalaryslippp(result)



                              })
                              .catch(error => console.log('error', error));


                          }}>Delete</button>
                          <button className='formalbtn'>Download</button>

                        </div>
                      </div>
                    </>}
                  </div>
                </>)
              })}
            </>}

          </div>



        </div>
      </>}  </div >
  )
}

export default PayslipSalarySystem