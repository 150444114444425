import React, { useState } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import "./Css/tasksandtracking.css"
import Cookies from 'js-cookie';


function TasksTracking() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")


  const [popup, setpopup] = useState(false)
  const [btn, setbtn] = useState(true)
  const [tags, setTags] = useState([]);


  const submitdata = async (e) => {



    await setbtn(false)
    e.preventDefault()






    setpopup(false)

    setbtn(true)




  }




  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const tagValue = event.target.value.trim();

      if (tagValue !== '') {
        setTags([...tags, tagValue]);
        event.target.value = ''; // Clear the input field after adding the tag
      }
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>


        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>


            <div className='header'>
              <h1 className='pppheading'>Task Managment </h1>


            </div>
            <div className='Tableheader'><h3></h3><button onClick={() => setpopup(true)} className='formalbtn'>Assign A Task</button></div>

            <div className='pendingtasksdiv'>

              <h3>New And Pending Tasks</h3>
              <div className='newtaskcontant'>

                <div style={{ display: 'flex' }}><h3 style={{ fontWeight: "normal" }}> sdcasfcdszfcvdszfsdgfsdgvfsdfvhdkjsvfckjdsgcvhfjgsdbhjfcgb lidsgfc isufd ietgfusgfeu dsdf dsi fysdoi ydhoi oif dsyfcusdfu dscvfy dsfudsfhcvgudgfou dfgsh syf sdyg ovisdifvgho svgoi fdhovgi gvfdh vgoishy vg dhvfois dhvdivghodvfd vgfd vgfisdh vf divghf oidvgfdvgf cvhdkjfchkdsgh</h3></div>
                <div style={{ display: 'flex' }}><h3>Deadline : </h3> <h3 style={{ fontWeight: "normal" }}> &nbsp; 21/5/2024</h3></div>

                <button className='formalbtn'>Mark as Done</button>
                <div style={{ display: 'flex', color: 'gray' }}><h5>Task Given by : </h5> <h5 style={{ fontWeight: "normal" }}> &nbsp; Ali Ahmed(aliahmed.samoo.1@gmail.com)</h5></div>

              </div>


            </div>

            <div className='taskingdoneandassign'>

              <div className='pendingtasksdiv'>

                <h3>Your Complate Tasks</h3>
                <div className='newtaskcontant'>

                  <div style={{ display: 'flex' }}> <h3 style={{ fontWeight: "normal" }}>sdcasfcdszfcvdszfsdgfsdgvfsdfvhdkjsvfckjdsgcvhfjgsdbhjfcgb lidsgfc isufd ietgfusgfeu dsdf dsi fysdoi ydhoi oif dsyfcusdfu dscvfy dsfudsfhcvgudgfou dfgsh syf sdyg ovisdifvgho svgoi fdhovgi gvfdh vgoishy vg dhvfois dhvdivghodvfd vgfd vgfisdh vf divghf oidvgfdvgf cvhdkjfchkdsgh</h3></div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}><div style={{ display: 'flex' }}><h3>Deadline : </h3> <h3 style={{ fontWeight: "normal" }}> &nbsp; 21/5/2024</h3></div><div style={{ display: 'flex' }}><h3>Complated on : </h3> <h3 style={{ fontWeight: "normal" }}> &nbsp; 22/5/2024</h3></div></div>

                  <div style={{ display: 'flex', color: 'gray' }}><h5>Task Given by : </h5> <h5 style={{ fontWeight: "normal" }}> &nbsp; Ali Ahmed(aliahmed.samoo.1@gmail.com)</h5></div>

                </div>


              </div>


              <div className='pendingtasksdiv'>

                <h3>Tasks! You Gave to Someone</h3>
                <div className='newtaskcontant'>

                  <div style={{ display: 'flex' }}> <h3 style={{ fontWeight: "normal" }}>  sdcasfcdszfcvdszfsdgfsdgvfsdfvhdkjsvfckjdsgcvhfjgsdbhjfcgb lidsgfc isufd ietgfusgfeu dsdf dsi fysdoi ydhoi oif dsyfcusdfu dscvfy dsfudsfhcvgudgfou dfgsh syf sdyg ovisdifvgho svgoi fdhovgi gvfdh vgoishy vg dhvfois dhvdivghodvfd vgfd vgfisdh vf divghf oidvgfdvgf cvhdkjfchkdsgh</h3></div>
                  <div style={{ display: 'flex' }}><h3>Deadline : </h3> <h3 style={{ fontWeight: "normal" }}> &nbsp; 21/5/2024</h3></div>

                  <table className='tablep'>
                    <thead className='tablephead'>
                      <tr>
                        <th>S NO.</th>
                        <th>Assign To</th>
                        <th>Status</th>

                      </tr>
                    </thead>
                    <tbody>

                      <tr>

                        <td>1</td>
                        <td>dsgfdfg </td>
                        <td>Complated</td>
                      </tr>

                      <tr>

                        <td>2</td>
                        <td>dsgfdfg </td>
                        <td>Complated</td>
                      </tr>

                      <tr>

                        <td>3</td>
                        <td>dsgfdfg </td>
                        <td>pendding</td>
                      </tr>

                      <tr>

                        <td>4</td>
                        <td>dsgfdfg </td>
                        <td>Complated</td>
                      </tr>
                    </tbody>
                  </table>
                </div>


              </div>

            </div>

          </div>
          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>
                <form onSubmit={submitdata} className='newenteryform'>

                  <div>
                    <input
                      type="text"
                      onKeyDown={handleKeyDown}
                      placeholder="Enter tags and press Enter"
                    />
                    <div>
                      {tags.map((tag, index) => (
                        <span key={index} className="tag">
                          {tag}
                          <button onClick={() => removeTag(index)}>X</button>
                        </span>
                      ))}
                    </div>
                  </div>



                  <div><label>DeadLine of this Task :</label><input type='date' required /></div>


                  <h2>Write Description of this Task :</h2>
                  <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} /></div>

                  {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                  </> : <>
                    <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                      e.preventDefault()
                      message.error("Please Wait")
                    }}>Please Wait</button>
                  </>}
                  <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

                </form>
              </div>
            </div>
          </>}
        </div>
      </>}  </div>
  )
}

export default TasksTracking