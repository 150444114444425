import React from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';


function Knowledge() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")


  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>


        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>


          <div className='Tableheader'><h1>Knowledge</h1><button  className='formalbtn'>Edit</button></div>

            <div className='content'>
             
              <section>
                <h2>Dashboard</h2>
                <p>This section provides an overview of your CRM activities.</p>
                {/* Dashboard content goes here */}
              </section>
              <section>
                <h2>Account Management</h2>
                <p>This section allows you to manage user accounts within your CRM.</p>
                {/* Account Management content goes here */}
              </section>
              <section>
                <h2>Staff Profiles</h2>
                <p>This section contains information about your team members.</p>
                {/* Staff Profiles content goes here */}
              </section>
              <section>
                <h2>Customer Accounts</h2>
                <p>This section stores information about your clients.</p>
                {/* Customer Accounts content goes here */}
              </section>
              <section>
                <h2>Finance</h2>
                <p>This section manages financial transactions and data within your CRM.</p>
                {/* Finance content goes here */}
              </section>
              <section>
                <h2>Financial Details</h2>
                <p>This section provides detailed financial information.</p>
                {/* Financial Details content goes here */}
              </section>
              <section>
                <h2>Invoices & Payments</h2>
                <p>This section handles invoices and payments.</p>
                {/* Invoices & Payments content goes here */}
              </section>
              <section>
                <h2>Credit notes</h2>
                <p>This section manages credit notes.</p>
                {/* Credit notes content goes here */}
              </section>
              <section>
                <h2>Commissions</h2>
                <p>This section tracks commissions.</p>
                {/* Commissions content goes here */}
              </section>
              <section>
                <h2>Payslip/Salary System</h2>
                <p>This section handles payslips and salary information.</p>
                {/* Payslip/Salary System content goes here */}
              </section>
              <section>
                <h2>Expenses</h2>
                <p>This section manages expenses.</p>
                {/* Expenses content goes here */}
              </section>
              <section>
                <h2>Sales and Subscription</h2>
                <p>This section handles sales and subscription information.</p>
                {/* Sales and Subscription content goes here */}
              </section>
              <section>
                <h2>Tasks & Tracking</h2>
                <p>This section manages tasks and tracks progress.</p>
                {/* Tasks & Tracking content goes here */}
              </section>
              <section>
                <h2>Subscriptions</h2>
                <p>This section handles subscriptions.</p>
                {/* Subscriptions content goes here */}
              </section>
              <section>
                <h2>Leads & Proposals</h2>
                <p>This section manages leads and proposals.</p>
                {/* Leads & Proposals content goes here */}
              </section>
              <section>
                <h2>Estimates</h2>
                <p>This section handles estimates.</p>
                {/* Estimates content goes here */}
              </section>
              <section>
                <h2>Marketing</h2>
                <p>This section manages marketing activities.</p>
                {/* Marketing content goes here */}
              </section>
              <section>
                <h2>Integrations</h2>
                <p>This section handles integrations with other platforms.</p>
                {/* Integrations content goes here */}
              </section>
              <section>
                <h2>Templates & Documents</h2>
                <p>This section manages templates and documents.</p>
                {/* Templates & Documents content goes here */}
              </section>
              <section>
                <h2>Communication</h2>
                <p>This section manages communication channels.</p>
                {/* Communication content goes here */}
              </section>
              <section>
                <h2>Chat & Call Support</h2>
                <p>This section provides chat and call support.</p>
                {/* Chat & Call Support content goes here */}
              </section>
              <section>
                <h2>Emails & SMS</h2>
                <p>This section handles emails and SMS.</p>
                {/* Emails & SMS content goes here */}
              </section>
              <section>
                <h2>Customer and company</h2>
                <p>This section manages customer and company information.</p>
                {/* Customer and company content goes here */}
              </section>
              <section>
                <h2>Contacts</h2>
                <p>This section manages contacts.</p>
                {/* Contacts content goes here */}
              </section>
              <section>
                <h2>Loyalty Program</h2>
                <p>This section manages loyalty programs.</p>
                {/* Loyalty Program content goes here */}
              </section>
              <section>
                <h2>Contracts</h2>
                <p>This section manages contracts.</p>
                {/* Contracts content goes here */}
              </section>
              <section>
                <h2>Human Resources</h2>
                <p>This section manages human resources.</p>
                {/* Human Resources content goes here */}
              </section>
              <section>
                <h2>Staff Details & Recruitment</h2>
                <p>This section manages staff details and recruitment.</p>
                {/* Staff Details & Recruitment content goes here */}
              </section>
              <section>
                <h2>Operations</h2>
                <p>This section manages operations.</p>
                {/* Operations content goes here */}
              </section>
              <section>
                <h2>Accounting & Projects</h2>
                <p>This section manages accounting and projects.</p>
                {/* Accounting & Projects content goes here */}
              </section>
              <section>
                <h2>Customer Support & Tickets</h2>
                <p>This section manages customer support and tickets.</p>
                {/* Customer Support & Tickets content goes here */}
              </section>
              <section>
                <h2>Purchase & Logistics</h2>
                <p>This section manages purchase and logistics.</p>
                {/* Purchase & Logistics content goes here */}
              </section>
              <section>
                <h2>Analytics</h2>
                <p>This section provides analytics.</p>
                {/* Analytics content goes here */}
              </section>
              <section>
                <h2>Customer Data Analytics</h2>
                <p>This section provides customer data analytics.</p>
                {/* Customer Data Analytics content goes here */}
              </section>
              <section>
                <h2>Logistics</h2>
                <p>This section manages logistics.</p>
                {/* Logistics content goes here */}
              </section>
              <section>
                <h2>Inventory</h2>
                <p>This section manages inventory.</p>
                {/* Inventory content goes here */}
              </section>
              <section>
                <h2>Suppliers</h2>
                <p>This section manages suppliers.</p>
                {/* Suppliers content goes here */}
              </section>
              <section>
                <h2>Assets</h2>
                <p>This section manages assets.</p>
                {/* Assets content goes here */}
              </section>
              <section>
                <h2>Purchases</h2>
                <p>This section manages purchases.</p>
                {/* Purchases content goes here */}
              </section>
              <section>
                <h2>Utilities</h2>
                <p>This section manages utilities.</p>
                {/* Utilities content goes here */}
              </section>
              <section>
                <h2>Calendar</h2>
                <p>This section provides a calendar.</p>
                {/* Calendar content goes here */}
              </section>
              <section>
                <h2>Knowledge</h2>
                <p>This section provides knowledge resources.</p>
                {/* Knowledge content goes here */}
              </section>
              <section>
                <h2>Reports and Analytics</h2>
                <p>This section provides reports and analytics.</p>
                {/* Reports and Analytics content goes here */}
              </section>
            </div>

          </div>
        </div>
      </>}  </div>
  )
}

export default Knowledge