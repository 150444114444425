import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import "./Css/calendar.css"
import { message } from 'antd';


function Calendar() {
  const id = Cookies.get("id");
  const token = Cookies.get("Token");
  const loginemail = Cookies.get("email");

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  // Initialize state with current year and month
  const currentDate = new Date();
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value));
  };

  // Function to get the number of days in a month
  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Function to generate an array of dates for a month
  const generateDates = (month, year) => {
    const numDays = daysInMonth(month, year);
    const firstDayOfMonth = new Date(year, month, 1).getDay(); // Get the weekday of the first day of the month (0 - Sunday, 1 - Monday, ...)
    const dates = Array.from({ length: firstDayOfMonth }, (_, i) => ''); // Create empty cells for days before the first day of the month
    for (let i = 1; i <= numDays; i++) {
      dates.push(i);
    }
    return dates;
  };


  // Function to chunk the dates into weeks
  const chunkArray = (arr, size) => {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  };


  const [eventyear, seteventyear] = useState("")
  const [eventmonth, seteventmonth] = useState("")
  const [eventday, seteventday] = useState("")
  const [eventtitle, seteventtitle] = useState("")
  const [eventdis, seteventdis] = useState("")
  const [events, setevents] = useState([




  ]);


  const [popup, setpopup] = useState(false)
  const [popup2, setpopup2] = useState(false)
  const [popup3, setpopup3] = useState(false)


  const [btn, setbtn] = useState(true)

  const [invoice, setinvoice] = useState([])
  const [device, setdevice] = useState([])
  const [staffprofiles, setstaffprofiles] = useState([])

  const [eventdetails, seteventdetails] = useState()

  useEffect(() => {




    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getevent", requestOptions)
      .then(response => response.json())
      .then(result => setevents(result))
      .catch(error => console.log('error', error));










    fetch("https://backend.datawarehousegh.com:1337/getinvoice", requestOptions)
      .then(response => response.json())
      .then(result => setinvoice(result))
      .catch(error => console.log('error', error));


    fetch("https://backend.datawarehousegh.com:1337/getdevices", requestOptions)
      .then(response => response.json())
      .then(result => setdevice(result))
      .catch(error => console.log('error', error));

    fetch("https://backend.datawarehousegh.com:1337/getCustomerprofiles", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));




  }, [])

  const [invoicedetails, setinvoicedetails] = useState([])



  const submitdata = async (e) => {

    await setbtn(false)
    e.preventDefault()



    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();

    urlencoded.append("eventyear", eventyear);
    urlencoded.append("eventmonth", eventmonth);
    urlencoded.append("eventday", eventday);
    urlencoded.append("eventtitle", eventtitle);
    urlencoded.append("eventdis", eventdis);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addevent", requestOptions)
      .then(response => response.json())
      .then(result => {




        setevents(result)
        setpopup3(false);

        setbtn(true);







      })
      .catch(error => console.log('error', error));



  }

  const generateDays = (month, year) => {
    const numDays = daysInMonth(month, year);
    return Array.from({ length: numDays }, (_, i) => i + 1);
  };
  const days = generateDays(eventmonth, eventyear);




  const dltevent = async (e) => {
    e.preventDefault()
    await setbtn(false)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", eventdetails._id);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/dtlevent", requestOptions)
      .then(response => response.json())
      .then(result => {
        setevents(result)
        setpopup(false);

        setbtn(true);




      })
      .catch(error => console.log('error', error));











  }

  return (
    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && (
        <>
          <Navbar />
          <div className='mainbody'>
            <Sidemanu />
            <div className='dashbordbody'>
              <h2>Calendar</h2>
              <div className="calendar-controls">
                <div>  <select value={selectedYear} onChange={handleYearChange}>
                  {Array.from({ length: 10 }, (_, i) => selectedYear - 5 + i).map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
                  <select value={selectedMonth} onChange={handleMonthChange}>
                    {months.map((month, index) => (
                      <option key={index} value={index}>{month}</option>
                    ))}
                  </select>
                </div>
                <div><button className='formalbtn' onClick={() => setpopup3(true)}>New Event</button></div>
              </div>
              <div className="calendar">
                <table>
                  <thead>
                    <tr>
                      {daysOfWeek.map(day => (
                        <th key={day}>{day}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {chunkArray(generateDates(selectedMonth, selectedYear), 7).map((week, index) => (
                      <tr key={index}>
                        {week.map((date, index) => (
                          <td key={index}>
                            <div className='calunder'>
                              <div className='calunder1'>  {date}
                              </div>

                              <div className='dateevents'>

                                {events.map((events) => {
                                  return (<>

                                    <div className='event' onClick={() => {
                                      setpopup(true)
                                      seteventdetails(events)
                                    }}>
                                      {(date === parseInt(events.eventday) && months[selectedMonth] === events.eventmonth && selectedYear === parseInt(events.eventyear))

                                        && <>
                                          {events.eventtitle}


                                        </>}


                                    </div>

                                  </>)
                                })}








                                {invoice.map((invoice) => {

                                  var InvoiceBillingDate = false

                                  var InvoiceDuedate = false


                                  const dateee = new Date(invoice.InvoiceBillingDate);

                                  const year = dateee.getFullYear();
                                  const monthNames = [
                                    "January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"
                                  ];
                                  const month = monthNames[dateee.getMonth()];
                                  const dayyy = dateee.getDate();



                                  if (
                                    date === parseInt(dayyy)
                                    &&
                                    months[selectedMonth] === month
                                    &&

                                    selectedYear === parseInt(year)

                                  ) {
                                    InvoiceBillingDate = true

                                  }



                                  const dateee2 = new Date(invoice.InvoiceDuedate);

                                  const year2 = dateee2.getFullYear();
                                  const monthNames2 = [
                                    "January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"
                                  ];
                                  const month2 = monthNames2[dateee.getMonth()];
                                  const dayyy2 = dateee2.getDate();



                                  if (
                                    date === parseInt(dayyy2)
                                    &&
                                    months[selectedMonth] === month2
                                    &&

                                    selectedYear === parseInt(year2)

                                  ) {
                                    InvoiceDuedate = true

                                  }


                                  return (<>

                                    <div className='event' onClick={() => {
                                      setpopup2(true)
                                      setinvoicedetails(invoice)
                                    }}>


                                      {InvoiceBillingDate === true && <>Invoice Billing Date  <br />  ( {invoice._id} ) <br /></>}
                                      {InvoiceDuedate === true && <>  Invoice Due date <br /> ( {invoice._id} )</>}

                                      {/* <p>Year: {year}</p> */}
                                      {/* <p>Month: {month}</p> */}


                                    </div>

                                  </>)
                                })}


                              </div>

                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}



      {popup === true && <>
        <div className='popup'>
          <div className='popupbg' onClick={() => setpopup(false)}></div>
          <div className='popupcontant'>
            <h2>Event Details</h2>
            <div style={{ margin: "20px" }}></div>
            <h2>Title : {eventdetails.eventtitle}</h2>

            <div> {eventdetails.eventday} / {eventdetails.eventmonth} / {eventdetails.eventyear}</div>
            <div style={{ margin: "20px" }}></div>
            <div style={{ margin: "20px" }}></div>

            <h3>Description</h3>
            <div>{eventdetails.eventdis}</div>
            <div style={{ margin: "20px" }}></div>
            <div style={{ margin: "20px" }}></div>
            <div style={{ margin: "20px" }}></div>
            <div style={{ margin: "20px" }}></div>


            <form className='newenteryform' onSubmit={dltevent} >
              {btn ? <>  <button className='formalbtn' type='submit'>Delete</button>
              </> : <>
                <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                  e.preventDefault()
                  message.error("Please Wait")
                }}>Please Wait</button>
              </>}
              <button className='formalbtncncl' onClick={() => setpopup(false)}>Close</button>
            </form>
          </div>
        </div>
      </>}


      {popup2 === true && <>
        <div className='popup'>
          <div className='popupbg' onClick={() => setpopup2(false)}></div>
          <div className='popupcontant'>
            <h2>Invoice Details Below</h2>



            {invoice.map((value) => {

              var CustomerName
              var Email
              var ID
              var Address


              var deviceType
              var manufacturer
              var IMEI
              var SimNumber

              staffprofiles.map((value2) => {

                if (value2._id === value.CustomerID) {

                  CustomerName = value2.FullName
                  Email = value2.email
                  ID = value2.IDNumber
                  Address = value2.Address

                }

              })

              device.map((value3) => {

                if (value3._id === value.DeviceID) {

                  deviceType = value3.deviceType
                  manufacturer = value3.manufacturer
                  IMEI = value3.IMEI
                  SimNumber = value3.SimNumber

                }

              })


              return (<>

                {invoicedetails._id === value._id && <>
                  <div>
                    <div className='invoicebox'>
                      <div className='invoiceinside'>
                        <div>
                          <h3>{CustomerName}</h3>
                          <p>{Email}</p>
                          <p>{ID}</p>
                          <p>{Address}</p>
                          <p>{Email}</p>


                          <p>   Ship to : {value.Shipto} </p>
                          <p>    Currency : {value.Currency} </p>
                          <p>   Invoice Billing Date : {value.InvoiceBillingDate} </p>
                          <p>   Invoice Due date : {value.InvoiceDuedate} </p>
                        </div>


                        <div>

                          <h3>{deviceType}</h3>
                          <p>{manufacturer}</p>
                          <p>{IMEI}</p>
                          <p>{SimNumber}</p>

                        </div>

                        <div>

                          <h3>Prices </h3>
                          <p>Device Price : {value.DevicePrice} </p>
                          <p>Package Price : {value.PackagePrice} </p>
                          <p>Sim Price : {value.SimPrice} </p>
                          <p>Taxes : {value.Taxes} </p>
                          <p>Other : {value.Other} </p>

                          <p>   Recurring  : {value.Recurring}    </p>
                          <p>   DiscountType  : {value.DiscountType}  %  </p>
                          <p>   TotalCycles  : {value.TotalCycles}    </p>
                          <p>  Depositpaid  : {value.Depositpaid}    </p>
                          <p>   Donatetoacharity  : {value.Donatetoacharity}    </p>
                          <p>   TotalToPay  : {value.TotalToPay}    </p>

                        </div>
                      </div>
                      <div>
                        <h3>{value.Package}</h3>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                        <button onClick={()=>setpopup2(false)} className='formalbtncncl'>Close</button>
                        <button className='formalbtn'>Download</button>

                      </div>
                    </div>

                  </div>
                </>}
              </>)
            })}





          </div>
        </div>
      </>}


      {popup3 === true && <>
        <div className='popup'>
          <div className='popupbg' onClick={() => setpopup3(false)}></div>
          <div className='popupcontant'>
            <h2>Enter the Details Below</h2>
            <form onSubmit={submitdata} className='newenteryform'>

              <div>  <label>Event :</label><input required value={eventtitle} onChange={(e) => seteventtitle(e.target.value)} /></div>
              <div className="calendar-controls">
                <select required value={eventyear} onChange={(e) => seteventyear(parseInt(e.target.value))}>
                  {Array.from({ length: 10 }, (_, i) => selectedYear - 5 + i).map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
                <select required value={eventmonth} onChange={(e) => seteventmonth(parseInt(e.target.value))}>
                  {months.map((month, index) => (
                    <option key={index} value={index}>{month}</option>
                  ))}
                </select>

                <select value={eventday} onChange={(e) => seteventday(e.target.value)}>
                  {days.map((day) => (
                    <option key={day} value={day}>{day}</option>
                  ))}
                </select>
              </div>


              <h2>Event Description</h2>
              <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} value={eventdis} onChange={(e) => seteventdis(e.target.value)} /></div>




              {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
              </> : <>
                <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                  e.preventDefault()
                  message.error("Please Wait")
                }}>Please Wait</button>
              </>}
              <button className='formalbtncncl' onClick={() => setpopup3(false)}>Cencel</button>

            </form>
          </div>
        </div>
      </>}

    </div>
  );
}

export default Calendar;