import React, { useState, useEffect } from 'react'
import "./Css/staffprofiles.css"
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate } from 'react-router-dom'
import Password from 'antd/es/input/Password'
import Cookies from 'js-cookie';


function Contacts() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  // const token = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
  const loginemail = Cookies.get("email")

  
  const Navigate = useNavigate()
  const [viewdetails, setviewdetails] = useState(false)
  const [popup, setpopup] = useState(false)

  const [Name , setName ] = useState("")
  const [Position , setPosition ] = useState("")
  const [Email , setEmail ] = useState("")
  const [Contact , setContact ] = useState("")
  const [Phone  , setPhone  ] = useState("")
  const [Address , setAddress ] = useState("")

 



  const [btn, setbtn] = useState(true)

  const [staffprofiles, setstaffprofiles] = useState([])





  const submitdata = async (e) => {

    // await setbtn(false)
    e.preventDefault()





    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("Name", Name );
    urlencoded.append("Position", Position );
    urlencoded.append("Email", Email );
    urlencoded.append("Contact", Contact );
    urlencoded.append("Phone", Phone );
    urlencoded.append("Address", Address  );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addcontact", requestOptions)
      .then(response => response.json())
      .then(result => {


        if (result === "user found") {
          message.error("Email is already in Database")
          setbtn(true)
        }
        else {

          setstaffprofiles(result)
          setpopup(false);

          setbtn(true);

          setName("")
          setPosition("")
          setEmail("")
          setContact("")
          setPhone("")
          setAddress("")
        

        

      
        }

      })
      .catch(error => console.log('error', error));



  }

  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getcontact", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));















  }, [])



  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

      <Navbar />
      <div className='mainbody'>
        <Sidemanu />
        <div className='dashbordbody'>



          <div className='header'>
            <h1 className='pppheading'> Contacts</h1>


          </div>
          {viewdetails === false && <>

            <div className='Tableheader'><h3></h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>

            {staffprofiles.length !== 0 && <>
              <table className='tablep'>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>
                    <th> Name</th>
                    <th> Position</th>

                    <th> Email</th>
                    <th> Phone</th>
                    {/* <th>ID Type</th> */}
                    <th> Address</th>
                   
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  {staffprofiles.map((value, index) => {

                    return (

                      <tr>

                        <td>{index + 1}</td>
               
                        <td>{value.Name }</td>
                        <td>{value.Position }</td>
                
                        {/* <td>{value.}</td> */}
                        <td>{value.Contact  }</td>
                        <td>{value.Phone }</td>
                        <td>{value.Address }</td>
                      
                    
                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtlcontact", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setstaffprofiles(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </>}
          </>}





        </div>


        {popup === true && <>
          <div className='popup'>
            <div className='popupbg' onClick={() => setpopup(false)}></div>
            <div className='popupcontant'>
              <h2>Enter the Details Below</h2>
              <form onSubmit={submitdata} className='newenteryform'>

                <div>  <label> Name :</label><input required value={Name} onChange={(e) => setName(e.target.value)} /></div>
                <div><label> Position :</label><input required value={Position} onChange={(e) => setPosition(e.target.value)} /></div>
                <div><label> Email :</label><input required value={Email} onChange={(e) => setEmail(e.target.value)} /></div>
                <div><label>Contact Details :</label><input required value={Contact} onChange={(e) => setContact(e.target.value)} /></div>
                <div><label> Phone :</label><input required value={Phone} onChange={(e) => setPhone(e.target.value)} /></div>
                <div><label> Address :</label><input required value={Address} onChange={(e) => setAddress(e.target.value)} /></div>
               

             


                {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                </> : <>
                  <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                    e.preventDefault()
                    message.error("Please Wait")
                  }}>Please Wait</button>
                </>}
                <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

              </form>
            </div>
          </div>
        </>}
      </div>
    </>}  </div>
  )
}

export default Contacts