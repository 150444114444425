import React, { useState, useRef, useMemo, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "./Css/integration.css"
import JoditEditor from 'jodit-react';
import axios from 'axios';

function Integrations() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const email = Cookies.get("email")
  const name = Cookies.get("Name")

  const [popup, setpopup] = useState(false)
  const [listemail, setlistemail] = useState([])

  const [to, setto] = useState("")

  const [sub, setsub] = useState("")


  const editor = useRef(null);
  const [content, setContent] = useState('');



  const formsub = async (e) => {
    e.preventDefault()


    const savedata = async () => {




      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("to", to);
      urlencoded.append("sub", sub);
      urlencoded.append("contant", content);
      urlencoded.append("fromname", name);
      urlencoded.append("fromemail", email);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      await fetch("https://backend.datawarehousegh.com:1337/emaillll", requestOptions)
        .then(response => response.text())
        .then(result => {

          setlistemail(result)
          setpopup(false)


          setto("")

          setsub("")



          setContent('');


        })
        .catch(error => console.log('error', error));



    }



    const url = 'https://api.sendinblue.com/v3/smtp/email';
    const apiKey = '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';

    const data = {
      sender: {
        name: 'Datawarehouse',
        email: 'info@Datawarehouse.pk'
      },
      to: [
        {
          email: to,
          // name: name
        }
      ],
      subject: sub,
      htmlContent: content
    };

    const headers = {
      'Content-Type': 'application/json',
      'api-key': apiKey
    };

    const ali = await axios.post(url, data, { headers })
      .then(response => {


        savedata()
        console.log(response)
      }

      )
      .catch(error => {

        console.log(error)
      }
      );






  }




  useEffect(() => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };


    fetch("https://backend.datawarehousegh.com:1337/getemaillll", requestOptions)
      .then(response => response.json())
      .then(result => setlistemail(result))
      .catch(error => console.log('error', error));



  }, [])



  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>


        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>


            <div className='header'>
              <h1 className='pppheading'> Integrations</h1>


            </div>

            <div className='Tableheader'><h3>Email </h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>

            <div className='emaillist'>

              {listemail.map((value) => <>
                <div className='emailbox'>
                  <h4>to : {value.to}</h4>
                  <h4>Subject : {value.sub}</h4>
                  <div dangerouslySetInnerHTML={{ __html: value.contant }} />

                  <div className='blogItem-author'>
                    <img loading='lazzy' src="/assets/images/author.jpg" alt='avatar' />
                    <div>
                      <h6>{value.fromname}</h6>
                      <h6>{value.fromemail}</h6>
                      <p className='nopading'>{value.timestamp}</p>
                    </div>
                  </div>
                </div>
              </>)}
            </div>

          </div>



          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>


                <form className='newenteryform2' onSubmit={formsub}>

                  <div className='insideform newenteryform2div'><label>to</label> <input required value={to} onChange={(e) => setto(e.target.value)} /></div>

                  <div className='insideform newenteryform2div'><label>Subject</label> <input required value={sub} onChange={(e) => setsub(e.target.value)} /></div>


                  <JoditEditor
                    className='JoditEditor'
                    ref={editor}
                    value={content}
                    // config={config}
                    // tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => setContent(newContent)}
                  />


                  <div className='insideform newenteryform2div'><label>Author Name</label> <input required disabled value={name} /></div>
                  <div className='insideform  newenteryform2div'><label>Author Email</label> <input disabled required value={email} /></div>




                  <button style={{ width: '100%', margin: '0' }} className="formalbtn">Post</button>

                </form>
              </div>
            </div>
          </>}


        </div>
      </>}  </div>
  )
}

export default Integrations