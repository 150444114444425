import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import "./Css/blog.css"
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { message } from 'antd';

function TemplatesDocuments() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const email = Cookies.get("email")
  const name = Cookies.get("Name")
  const Navigate = useNavigate()

  // const blogList = [
  //   {
  //     id: 1,
  //     title: '7 CSS tools you should be using ',
  //     category: 'development',
  //     subCategory: ['frontend', 'ui/ux', 'design'],
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     authorName: 'John Doe',
  //     authorAvatar: '/assets/images/author.jpg',
  //     createdAt: 'June 03, 2021',
  //     cover: '/assets/images/designer-1.jpg',
  //   },
  //   {
  //     id: 2,
  //     title: 'Milan Places That Highlight The City',
  //     category: 'travel',
  //     subCategory: ['vacation', 'holidays', 'sight seeing'],
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     authorName: 'John Doe',
  //     authorAvatar: '/assets/images/author.jpg',
  //     createdAt: 'June 03, 2021',
  //     cover: '/assets/images/f67396fc3cfce63a28e07ebb35d974ac.jpg',
  //   },
  //   {
  //     id: 3,
  //     title: 'Online Shopping – An Alternative to Shopping in the Mall',
  //     category: 'shopping',
  //     subCategory: ['e-commerce store', 'clothing', 'shopping store'],
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     authorName: 'John Doe',
  //     authorAvatar: '/assets/images/author.jpg',
  //     createdAt: 'June 03, 2021',
  //     cover: '/assets/images/fQwuyKJ9qxjSbr6REcgtmW-1200-80.jpg',
  //   },
  //   {
  //     id: 4,
  //     title: 'ADVENTURE IN YOU',
  //     category: 'adventure',
  //     subCategory: ['adrenaline', 'stay-fit', 'lifestyle'],
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     authorName: 'John Doe',
  //     authorAvatar: '/assets/images/author.jpg',
  //     createdAt: 'June 03, 2021',
  //     cover: '/assets/images/graphic-design-trends.png',
  //   },
  //   {
  //     id: 5,
  //     title: 'Loaded BBQ Baked Potatoes',
  //     category: 'cooking',
  //     subCategory: ['bbq', 'food', 'lifestyle'],
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     authorName: 'John Doe',
  //     authorAvatar: '/assets/images/author.jpg',
  //     createdAt: 'June 03, 2021',
  //     cover: '/assets/images/make-it-personal.jpg',
  //   },
  //   {
  //     id: 6,
  //     title: 'Beyond the Beach',
  //     category: 'travel',
  //     subCategory: ['beaches', 'sea', 'holidays'],
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     authorName: 'John Doe',
  //     authorAvatar: '/assets/images/author.jpg',
  //     createdAt: 'June 03, 2021',
  //     cover:
  //       '/assets/images/Purple-Combination-colors-graphic-design-predictions-1024x576-1024x576.jpg',
  //   },
  //   {
  //     id: 7,
  //     title: 'Art & Perception',
  //     category: 'art',
  //     subCategory: ['skill', 'design', 'passion'],
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     authorName: 'John Doe',
  //     authorAvatar: '/assets/images/author.jpg',
  //     createdAt: 'June 03, 2021',
  //     cover: '/assets/images/Synthwave-Postmodern.jpg',
  //   },
  // ];

  const [popup, setpopup] = useState(false)



  const [title, settitle] = useState("");
  const [category, setcategory] = useState("");
  const [description, setdescription] = useState("");
  const [cover, setcover] = useState("");
  const [Img, setImg] = useState(false)

  const [cv, setcv] = useState()

  const handleChangeimage = async (e) => {
    // console.log(e.target.files[0])
    setImg(e.target.files[0]);


  }
  const [btn, setbtn] = useState(true)

  const formsub = async (e) => {
    e.preventDefault()







    await setbtn(false)


    const Carimageid2 = v4();
    const imgRef2 = ref(imageDb, `profiles/${Carimageid2}`)
    await uploadBytes(imgRef2, Img)
    const imgUrl = await getDownloadURL(imgRef2);


    console.log(imgUrl)


    const Carimageid = v4();
    const ex = cv.name.split('.')[1]
    const imgRef = ref(imageDb, `files/${Carimageid}.${ex}`)
    await uploadBytes(imgRef, cv)
    const cvUrl = await getDownloadURL(imgRef);


    console.log(cvUrl)


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();

    // urlencoded.append("title", title);
    urlencoded.append("category", category);
    urlencoded.append("title", title);
    urlencoded.append("description", description);
    urlencoded.append("authorName", name);
    urlencoded.append("authoremail", email);
    urlencoded.append("cover", imgUrl);
    urlencoded.append("authorid", id);
    urlencoded.append("file", cvUrl);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };


    await fetch("https://backend.datawarehousegh.com:1337/upload", requestOptions)
      .then(response => response.json())
      .then(result => {



        setblogList(result)

        setpopup(false)

        settitle("");
        setcategory("");
        setdescription("");
        setcover("");
        setImg()

        setbtn(true)

      })
      .catch(error => console.log('error', error));


  }


  const [blogList, setblogList] = useState([]);


  useEffect(() => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");



    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };


    fetch("https://backend.datawarehousegh.com:1337/apblog", requestOptions)
      .then(response => response.json())
      .then(result => setblogList(result))
      .catch(error => console.log('error', error));



  }, [])









  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>


        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>



            <div className='header'>
              <h1 className='pppheading'> Templates & Documents</h1>


            </div>

            <div className='Tableheader'><h3></h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>




            <div>


              <div className='blogList-wrap'>
                {blogList.map((blog) => (


                  <div className='blogItem-wrap'>
                    <img loading='lazzy' className='blogItem-cover nopading' src={blog.cover} alt='cover' onClick={() => {

                      const link = document.createElement('a');
                      link.href = blog.file;
                      link.setAttribute('download', ''); // This will prompt the browser to download the file
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }} />

                    <p className='chip'>{blog.category}</p>
                    <h3 className='nopading'>{blog.title}</h3>
                    <p className='blogItem-desc nopading'>{blog.description}</p>
                    <footer>
                      <div className='blogItem-author'>
                        <img loading='lazzy' src='/assets/images/author.jpg' alt='avatar' />
                        <div>
                          <h6>{blog.authorName}</h6>
                          <p className='nopading'>{blog.timestamp}</p>
                        </div>
                      </div>

                      <div className='blogItem-author'>
                        <button className='formalbtncncl' onClick={() => {

                          var myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                          var urlencoded = new URLSearchParams();
                          urlencoded.append("_id", blog._id);


                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                          };

                           fetch("https://backend.datawarehousegh.com:1337/dtldoc", requestOptions)
                            .then(response => response.json())
                            .then(result => {
                              setblogList(result)



                            })
                            .catch(error => console.log('error', error));




                        }
                        }>Delete</button>

                      </div>


                    </footer>
                  </div>




                ))}
              </div>


            </div>







          </div>


          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>


                <form className='newenteryform' onSubmit={formsub}>

                  <div className='insideform'><label>Title</label> <input required value={title} onChange={(e) => settitle(e.target.value)} /></div>
                  <div className='insideform'><label>Category</label> <input required value={category} onChange={(e) => setcategory(e.target.value)} /></div>

                  <div className='insideform'><label>Cover Photo</label> <input accept="image/*" onChange={handleChangeimage} required type='file' /></div>
                  <div className='insideform'><label>upload File :</label><input onChange={(e) => setcv(e.target.files[0])} required type='file' /></div>

                  <div className='insideform'><label>Description</label> <textarea required value={description} onChange={(e) => setdescription(e.target.value)} /></div>

                  <div className='insideform'><label>Author Name</label> <input required disabled value={name} /></div>
                  <div className='insideform'><label>Author Email</label> <input disabled required value={email} /></div>




                  {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                  </> : <>
                    <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                      e.preventDefault()
                      message.error("Please Wait")
                    }}>Please Wait</button>
                  </>}
                  <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

                </form>
              </div>
            </div>
          </>}


        </div>
      </>}  </div>
  )
}

export default TemplatesDocuments