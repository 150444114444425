import React, { useState, useEffect } from 'react'
import "./Css/staffprofiles.css"
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { Image, message } from 'antd'
import { TiEdit } from "react-icons/ti";
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie';
import { FaFacebookSquare, FaInstagram, FaYoutube,FaTiktok  } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { AiFillTikTok } from "react-icons/ai";


function StaffProfiles2() {
  const idd = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")



  const [popup, setpopup] = useState(false)

  const [FullName, setFullName] = useState("")
  const [DateOfBirth, setDateOfBirth] = useState("")
  const [Address, setAddress] = useState("")
  const [ContactDetails, setContactDetails] = useState("")
  const [NextOfKinNameandContactNumber, setNextOfKinNameandContactNumber] = useState("")
  const [SocailSecurityNumber, setSocailSecurityNumber] = useState("")
  const [IDNumber, setIDNumber] = useState("")
  const [EmergancyContactNumberAndName, setEmergancyContactNumberAndName] = useState("")
  const [Title, setTitle] = useState("")
  const [Department, setDepartment] = useState("")
  const [Supervisor, setSupervisor] = useState("")
  const [WorkLocation, setWorkLocation] = useState("")
  const [StartingDate, setStartingDate] = useState("")
  const [Salary, setSalary] = useState("")
  const [FacebookLink, setFacebookLink] = useState("")
  const [TwitterLink, setTwitterLink] = useState("")
  const [TiktiokLink, setTiktiokLink] = useState("")
  const [InstagramLink, setInstagramLink] = useState("")
  const [youtubeLink, setyoutubeLink] = useState("")
  const [PerformanceAppraisalRelatedNotes, setPerformanceAppraisalRelatedNotes] = useState("")
  const [OfficalName, setOfficalName] = useState("")
  const [OfficalPosition, setOfficalPosition] = useState("")
  const [AppraisalDate, setAppraisalDate] = useState("")

  const [cv, setcv] = useState("")
  const [img, setimg] = useState("")


  const [FullName2, setFullName2] = useState("")
  const [DateOfBirth2, setDateOfBirth2] = useState("")
  const [Address2, setAddress2] = useState("")
  const [ContactDetails2, setContactDetails2] = useState("")
  const [NextOfKinNameandContactNumber2, setNextOfKinNameandContactNumber2] = useState("")
  const [SocailSecurityNumber2, setSocailSecurityNumber2] = useState("")
  const [IDNumber2, setIDNumber2] = useState("")
  const [EmergancyContactNumberAndName2, setEmergancyContactNumberAndName2] = useState("")
  const [Title2, setTitle2] = useState("")
  const [Department2, setDepartment2] = useState("")
  const [Supervisor2, setSupervisor2] = useState("")
  const [WorkLocation2, setWorkLocation2] = useState("")
  const [StartingDate2, setStartingDate2] = useState("")
  const [Salary2, setSalary2] = useState("")
  const [FacebookLink2, setFacebookLink2] = useState("")
  const [TwitterLink2, setTwitterLink2] = useState("")
  const [TiktiokLink2, setTiktiokLink2] = useState("")
  const [InstagramLink2, setInstagramLink2] = useState("")
  const [youtubeLink2, setyoutubeLink2] = useState("")
  const [PerformanceAppraisalRelatedNotes2, setPerformanceAppraisalRelatedNotes2] = useState("")
  const [OfficalName2, setOfficalName2] = useState("")
  const [OfficalPosition2, setOfficalPosition2] = useState("")
  const [AppraisalDate2, setAppraisalDate2] = useState("")
  const [cv2, setcv2] = useState("")
  const [img2, setimg2] = useState("")


  const [btn, setbtn] = useState(true)

  const [staffprofiles, setstaffprofiles] = useState([])
  const { id } = useParams();




  const submitdata = async (e) => {

    await setbtn(false)
    e.preventDefault()





    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);
    urlencoded.append("FullName", FullName);
    urlencoded.append("DateOfBirth", DateOfBirth);
    urlencoded.append("Address", Address);
    urlencoded.append("ContactDetails", ContactDetails);
    urlencoded.append("NextOfKinNameandContactNumber", NextOfKinNameandContactNumber);
    urlencoded.append("SocailSecurityNumber", SocailSecurityNumber);
    urlencoded.append("IDNumber", IDNumber);
    urlencoded.append("EmergancyContactNumberAndName", EmergancyContactNumberAndName);
    urlencoded.append("Title", Title);
    urlencoded.append("Department", Department);
    urlencoded.append("Supervisor", Supervisor);
    urlencoded.append("WorkLocation", WorkLocation);
    urlencoded.append("StartingDate", StartingDate);
    urlencoded.append("Salary", Salary);
    urlencoded.append("FacebookLink", FacebookLink);
    urlencoded.append("TwitterLink", TwitterLink);
    urlencoded.append("TiktiokLink", TiktiokLink);
    urlencoded.append("InstagramLink", InstagramLink);
    urlencoded.append("youtubeLink", youtubeLink);
    urlencoded.append("PerformanceAppraisalRelatedNotes", PerformanceAppraisalRelatedNotes);



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addstaffedit", requestOptions)
      .then(response => response.json())
      .then(result => {



        setpopup(false)

        setFullName(result.FullName)
        setDateOfBirth(result.DateOfBirth)
        setAddress(result.Address)
        setContactDetails(result.ContactDetails)
        setNextOfKinNameandContactNumber(result.NextOfKinNameandContactNumber)
        setSocailSecurityNumber(result.SocailSecurityNumber)
        setIDNumber(result.IDNumber)
        setEmergancyContactNumberAndName(result.EmergancyContactNumberAndName)
        setTitle(result.Title)
        setDepartment(result.Department)
        setSupervisor(result.Supervisor)
        setWorkLocation(result.WorkLocation)
        setStartingDate(result.StartingDate)
        setSalary(result.Salary)
        setFacebookLink(result.FacebookLink)
        setTwitterLink(result.TwitterLink)
        setTiktiokLink(result.TiktiokLink)
        setInstagramLink(result.InstagramLink)
        setyoutubeLink(result.youtubeLink)
        setPerformanceAppraisalRelatedNotes(result.PerformanceAppraisalRelatedNotes)
        setOfficalName(result.OfficalName)
        setOfficalPosition(result.OfficalPosition)
        setAppraisalDate(result.AppraisalDate)





        setFullName2(result.FullName)
        setDateOfBirth2(result.DateOfBirth)
        setAddress2(result.Address)
        setContactDetails2(result.ContactDetails)
        setNextOfKinNameandContactNumber2(result.NextOfKinNameandContactNumber)
        setSocailSecurityNumber2(result.SocailSecurityNumber)
        setIDNumber2(result.IDNumber)
        setEmergancyContactNumberAndName2(result.EmergancyContactNumberAndName)
        setTitle2(result.Title)
        setDepartment2(result.Department)
        setSupervisor2(result.Supervisor)
        setWorkLocation2(result.WorkLocation)
        setStartingDate2(result.StartingDate)
        setSalary2(result.Salary)
        setFacebookLink2(result.FacebookLink)
        setTwitterLink2(result.TwitterLink)
        setTiktiokLink2(result.TiktiokLink)
        setInstagramLink2(result.InstagramLink)
        setyoutubeLink2(result.youtubeLink)
        setPerformanceAppraisalRelatedNotes2(result.PerformanceAppraisalRelatedNotes)
        setOfficalName2(result.OfficalName)
        setOfficalPosition2(result.OfficalPosition)
        setAppraisalDate2(result.AppraisalDate)



        // setstaffprofiles(result)


        // setFullName("");
        // setDateOfBirth("");
        // setAddress("");
        // setContactDetails("");
        // setNextOfKinNameandContactNumber("");
        // setSocailSecurityNumber("");
        // setIDNumber("");
        // setEmergancyContactNumberAndName("");
        // setTitle("");
        // setDepartment("");
        // setSupervisor("");
        // setWorkLocation("");
        // setStartingDate("");
        // setSalary("");
        // setFacebookLink("");
        // setTwitterLink("");
        // setTiktiokLink("");
        // setInstagramLink("");
        // setyoutubeLink("");
        // setPerformanceAppraisalRelatedNotes("");

        // setpopup(false)

        setbtn(true)


      })
      .catch(error => console.log('error', error));



  }

  useEffect(() => {



    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/viewstaffprofile", requestOptions)
      .then(response => response.json())
      .then(result => {

        setpopup(false)

        setFullName(result.FullName)
        setDateOfBirth(result.DateOfBirth)
        setAddress(result.Address)
        setContactDetails(result.ContactDetails)
        setNextOfKinNameandContactNumber(result.NextOfKinNameandContactNumber)
        setSocailSecurityNumber(result.SocailSecurityNumber)
        setIDNumber(result.IDNumber)
        setEmergancyContactNumberAndName(result.EmergancyContactNumberAndName)
        setTitle(result.Title)
        setDepartment(result.Department)
        setSupervisor(result.Supervisor)
        setWorkLocation(result.WorkLocation)
        setStartingDate(result.StartingDate)
        setSalary(result.Salary)
        setFacebookLink(result.FacebookLink)
        setTwitterLink(result.TwitterLink)
        setTiktiokLink(result.TiktiokLink)
        setInstagramLink(result.InstagramLink)
        setyoutubeLink(result.youtubeLink)
        setPerformanceAppraisalRelatedNotes(result.PerformanceAppraisalRelatedNotes)
        setOfficalName(result.OfficalName)
        setOfficalPosition(result.OfficalPosition)
        setAppraisalDate(result.AppraisalDate)

        setcv(result.cv)
        setimg(result.profilepic)


        setFullName2(result.FullName)
        setDateOfBirth2(result.DateOfBirth)
        setAddress2(result.Address)
        setContactDetails2(result.ContactDetails)
        setNextOfKinNameandContactNumber2(result.NextOfKinNameandContactNumber)
        setSocailSecurityNumber2(result.SocailSecurityNumber)
        setIDNumber2(result.IDNumber)
        setEmergancyContactNumberAndName2(result.EmergancyContactNumberAndName)
        setTitle2(result.Title)
        setDepartment2(result.Department)
        setSupervisor2(result.Supervisor)
        setWorkLocation2(result.WorkLocation)
        setStartingDate2(result.StartingDate)
        setSalary2(result.Salary)
        setFacebookLink2(result.FacebookLink)
        setTwitterLink2(result.TwitterLink)
        setTiktiokLink2(result.TiktiokLink)
        setInstagramLink2(result.InstagramLink)
        setyoutubeLink2(result.youtubeLink)
        setPerformanceAppraisalRelatedNotes2(result.PerformanceAppraisalRelatedNotes)
        setOfficalName2(result.OfficalName)
        setOfficalPosition2(result.OfficalPosition)
        setAppraisalDate2(result.AppraisalDate)
        setcv2(result.cv)
        setimg2(result.profilepic)




      }


      )
      .catch(error => console.log('error', error));



  }, [])



  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>



            <div className='header'>
              <h1 className='pppheading'> Staff Profiles</h1>


            </div>

            <div className='Tableheader'><h3></h3><button onClick={() => setpopup(true)} className='formalbtn' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><TiEdit style={{ width: '20px', height: '20px' }} />Edit</button></div>





            <div style={{ display: 'flex', width: '100%', height: "fit-contant", gap: '10px' }}>
              <div className='profilepix' style={{ width: '40%' }} >
                <Image height={"200px"} src={img2 || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY022kOu3BE3CXHtOafMV2bG3T9nUI-fsYJVpKSRCPfQ&s'} alt='' />
              </div>
              <div style={{ width: '60%' }}>

                <div className='infodivvv'> <h3>Full Name :</h3> <h3> &nbsp;{FullName2}</h3> </div>
                <div className='infodivvv'> <h3>Date Of Birth :</h3> <h3> &nbsp;{DateOfBirth2}</h3> </div>
                <div className='infodivvv'> <h3>Address :</h3> <h3> &nbsp;{Address2}</h3> </div>
                <div className='infodivvv'> <h3>Contact Details :</h3> <h3> &nbsp;{ContactDetails2}</h3> </div>
                <div className='infodivvv'> <h3>Next Of Kin Name and Contact Number :</h3> <h3> &nbsp;{NextOfKinNameandContactNumber2}</h3> </div>
                <div className='infodivvv'> <h3>Socail Security Number :</h3> <h3> &nbsp;{SocailSecurityNumber2}</h3> </div>
                <div className='infodivvv'> <h3>ID Number :</h3> <h3> &nbsp;{IDNumber2}</h3> </div>
                <div className='infodivvv'> <h3>Emergancy Contact Number And Name :</h3> <h3> &nbsp;{EmergancyContactNumberAndName2}</h3> </div>


              </div>
            </div>
            <h2 style={{ marginTop: '50px' }}>Socail Media Links</h2>
            <div className='socailmedialinks'>
              <a href={"https://"+FacebookLink}>

                <FaFacebookSquare style={{ width: '80px', height: '80px', color: "#1877F2" }} width={"100px"} height={"100px"} />
              </a>
              <a href={"https://"+TwitterLink}>

                <FaXTwitter style={{ width: '80px', height: '80px', color: "#1DA1F2 " }} width={"100px"} height={"100px"} />
              </a>

              <a href={"https://"+TiktiokLink}>

                <FaTiktok  style={{ width: '80px', height: '80px', color: "#ff0050 " }} width={"100px"} height={"100px"} />
              </a>


              <a href={"https://"+InstagramLink}>

                <FaInstagram style={{ width: '80px', height: '80px', color: "#FCAF45 " }} width={"100px"} height={"100px"} />
              </a>

              <a href={"https://"+youtubeLink}>

                <FaYoutube style={{ width: '80px', height: '80px', color: "#FF0000 " }} width={"100px"} height={"100px"} />
              </a>


            </div>

            <h2 style={{ marginTop: '50px' }}>Job Information</h2>

            <div className='infodivvv'> <h3>Title :</h3> <h3> &nbsp;{Title2}</h3> </div>
            <div className='infodivvv'> <h3>Department :</h3> <h3> &nbsp;{Department2}</h3> </div>
            <div className='infodivvv'> <h3>Supervisor :</h3> <h3> &nbsp;{Supervisor2}</h3> </div>
            <div className='infodivvv'> <h3>Work Location :</h3> <h3> &nbsp;{WorkLocation2}</h3> </div>
            <div className='infodivvv'> <h3>Starting Date :</h3> <h3> &nbsp;{StartingDate2}</h3> </div>
            <div className='infodivvv'> <h2>Salary :</h2> <h2> &nbsp; {Salary2}</h2> </div>


            <h2 style={{ marginTop: '50px' }}>Performance Appraisal Related Notes</h2>

            <div className='infodivvv'> <h3> &nbsp;{PerformanceAppraisalRelatedNotes2}</h3> </div>
            <div className='infodivvv'> <h3>Offical's Name :</h3> <h3> &nbsp; {OfficalName2}</h3> </div>

            <div className='infodivvv'> <h3>Offical's Position :</h3> <h3> &nbsp; {OfficalPosition2}</h3> </div>

            <div className='infodivvv'> <h3>Appraisal's Date :</h3> <h3> &nbsp; {AppraisalDate2}</h3> </div>





            <button className='formalbtn' onClick={() => {
              const link = document.createElement('a');
              link.href = cv;
              link.setAttribute('download', ''); // This will prompt the browser to download the file
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);


            }} >Download CV</button>


          </div>


          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>
                <form onSubmit={submitdata} className='newenteryform'>

                  <div>  <label>Full Name :</label><input required value={FullName} onChange={(e) => setFullName(e.target.value)} /></div>
                  <div><label>Date Of Birth :</label><input required value={DateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} /></div>
                  <div><label>Address :</label><input required value={Address} onChange={(e) => setAddress(e.target.value)} /></div>
                  <div><label>Contact Details :</label><input required value={ContactDetails} onChange={(e) => setContactDetails(e.target.value)} /></div>
                  <div><label>Next Of Kin Name and Contact Number :</label><input required value={NextOfKinNameandContactNumber} onChange={(e) => setNextOfKinNameandContactNumber(e.target.value)} /></div>
                  <div><label>Socail Security Number :</label><input required value={SocailSecurityNumber} onChange={(e) => setSocailSecurityNumber(e.target.value)} /></div>
                  <div><label>ID Number :</label><input required value={IDNumber} onChange={(e) => setIDNumber(e.target.value)} /></div>
                  <div><label>Emergancy Contact Number And Name  :</label><input required value={EmergancyContactNumberAndName} onChange={(e) => setEmergancyContactNumberAndName(e.target.value)} /></div>

                  <h2>Job Imformation</h2>
                  <div><label>Title :</label><input required value={Title} onChange={(e) => setTitle(e.target.value)} /></div>
                  <div><label>Department :</label><input required value={Department} onChange={(e) => setDepartment(e.target.value)} /></div>
                  <div><label>Supervisor :</label><input required value={Supervisor} onChange={(e) => setSupervisor(e.target.value)} /></div>
                  <div><label>Work Location :</label><input required value={WorkLocation} onChange={(e) => setWorkLocation(e.target.value)} /></div>
                  <div><label>Starting Date :</label><input required value={StartingDate} onChange={(e) => setStartingDate(e.target.value)} /></div>
                  <div><label>Salary :</label><input required value={Salary} onChange={(e) => setSalary(e.target.value)} /></div>

                  <h2>Socail Media Links</h2>
                  <div><label>Facebook Link :</label><input required value={FacebookLink} onChange={(e) => setFacebookLink(e.target.value)} /></div>
                  <div><label>Twitter(X) Link :</label><input required value={TwitterLink} onChange={(e) => setTwitterLink(e.target.value)} /></div>
                  <div><label>Tiktiok Link :</label><input required value={TiktiokLink} onChange={(e) => setTiktiokLink(e.target.value)} /></div>
                  <div><label>Instagram Link :</label><input required value={InstagramLink} onChange={(e) => setInstagramLink(e.target.value)} /></div>
                  <div><label>youtube Link :</label><input required value={youtubeLink} onChange={(e) => setyoutubeLink(e.target.value)} /></div>

                  <h2>Performance Appraisal Related Notes</h2>
                  <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} value={PerformanceAppraisalRelatedNotes} onChange={(e) => setPerformanceAppraisalRelatedNotes(e.target.value)} /></div>
                  <div><label>Offical's Name :</label><input required value={OfficalName} onChange={(e) => setOfficalName(e.target.value)} /></div>
                  <div><label>Offical's Position :</label><input required value={OfficalPosition} onChange={(e) => setOfficalPosition(e.target.value)} /></div>
                  <div><label>Appraisal's Date :</label><input required value={AppraisalDate} onChange={(e) => setAppraisalDate(e.target.value)} /></div>

                  {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                  </> : <>
                    <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                      e.preventDefault()
                      message.error("Please Wait")
                    }}>Please Wait</button>
                  </>}
                  <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

                </form>
              </div>
            </div>
          </>}
        </div>
      </>}  </div>
  )
}

export default StaffProfiles2