import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';



function ChatCallSupport() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")



  const Navigate = useNavigate()




  const [staffprofiles, setstaffprofiles] = useState([])



  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getcontactus", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));















  }, [])

  var count = 0;

  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>





            <div className='Tableheader'><h3>New Entery Forms</h3></div>

            {staffprofiles.length !== 0 && <>
              <table className='tablep'>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>
                    <th>Name</th>
                    <th>Subject</th>

                    <th>Contact Number</th>
                    <th>Emaile</th>
                    {/* <th>ID Type</th> */}
                    <th>Message</th>

                  
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  {staffprofiles.map((value, index) => {

                    return (<>
                      {value.status === "Newform" && <>
                        <tr>

                          <td>{index + 1}</td>
                     
                          <td>{value.Name}</td>
                          <td>{value.Subject}</td>
                         
                          {/* <td>{value.}</td> */}
                          <td>{value.Phone}</td>

                         
                          <td>{value.Message}</td>

                          <td><a href='#' onClick={(e) => {

                            e.preventDefault()

                            // Navigate("/StaffDetailsRecruitment/view/" + value._id)
                            // setviewdetails(true)

                          }}>Mark as done</a></td>
                          <td>
                            <AiFillDelete className='hovar' onClick={async () => {

                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", value._id);


                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };

                              await fetch("https://backend.datawarehousegh.com:1337/dtlstaffprofilescareer", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                  setstaffprofiles(result)



                                })
                                .catch(error => console.log('error', error));


                            }} style={{ color: "red", width: '25px', height: '25px' }} />
                          </td>

                        </tr>
                      </>}</>)
                  })}

                </tbody>
              </table>
            </>}


          




          </div>
        </div>
      </>}  </div>
  )
}

export default ChatCallSupport