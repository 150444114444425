import React from 'react'
import "./css/sidemanu.css"
import { MdDashboard, MdSupervisorAccount, MdAutoGraph, MdAttachEmail, MdConnectWithoutContact, MdEngineering } from "react-icons/md";
import { FaMoneyCheckAlt, FaHospitalUser, FaTruck } from "react-icons/fa";
import { FaUsersGear } from "react-icons/fa6";
import { BsGraphUpArrow } from "react-icons/bs";
import { GiAutoRepair } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';


function Sidemanu() {
    const Navigate = useNavigate();
    return (
        <div className='sidemanu'>

            {/* Dashboard */}
            <div className='manu'>

                <div onClick={() => Navigate("/dashboard")} className='iconnav2'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                    <MdDashboard style={{ width: '40px', height: '40px' }} />Dashboard</h3>





                </div>

            </div>

            {/* Finance */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><FaMoneyCheckAlt style={{ width: '40px', height: '40px' }} />Finance</h3>


                    <div className='navmaunu'>
                        <ul>

                            <li onClick={() => Navigate("/FinancialDetails")} className='navigater'>Financial Details</li>
                            <li onClick={() => Navigate("/InvoicesPayments")} className='navigater'>Invoices</li>
                            <li onClick={() => Navigate("/InvoicesPayments")} className='navigater'>Payments</li>
                            <li onClick={() => Navigate("/Creditnotes")} className='navigater'>Credit notes</li>
                            <li onClick={() => Navigate("/Commissions")} className='navigater'>Commissions</li>
                            <li onClick={() => Navigate("/Expenses")} className='navigater'>Expenses</li>


                        </ul>
                    </div>


                </div>




            </div>

            {/* Subcription */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><MdAutoGraph style={{ width: '40px', height: '40px' }} />Subcription</h3>


                    <div className='navmaunu'>
                        <ul>

                            <li onClick={() => Navigate("/Subscriptions")} className='navigater'>Mifi Subscriptions</li>
                            <li onClick={() => Navigate("/Subscriptions")} className='navigater'>Router Subscriptions</li>
                            <li onClick={() => Navigate("/Subscriptions")} className='navigater'>Dongle Subscriptions</li>
                            <li onClick={() => Navigate("/Subscriptions")} className='navigater'>TV Subscriptions</li>
                            <li onClick={() => Navigate("/Subscriptions")} className='navigater'>DeskPhone Subscriptions</li>
                            <li onClick={() => Navigate("/Subscriptions")} className='navigater'>Andriod TV Box Subscriptions</li>
                            <li onClick={() => Navigate("/Subscriptions")} className='navigater'>POE Subscriptions</li>


                        </ul>
                    </div>


                </div>




            </div>

            {/* Human Resources */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><FaUsersGear style={{ width: '40px', height: '40px' }} />Human Resources</h3>


                    <div className='navmaunu'>
                        <ul>
                            <li onClick={() => Navigate("/StaffProfiles")} className='navigater'>Staff Details</li>
                            <li onClick={() => Navigate("/payslip")} className='navigater'>Payslip</li>
                            <li onClick={() => Navigate("/PayslipSalarySystem")} className='navigater'>Salary System</li>


                            <li onClick={() => Navigate("/StaffDetailsRecruitment")} className='navigater'>Recruitment</li>
                        </ul>
                    </div>


                </div>




            </div>

            {/* Operations */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><MdEngineering style={{ width: '40px', height: '40px' }} />Operations</h3>


                    <div className='navmaunu'>
                        <ul>
                            <li onClick={() => Navigate("/AccountingProjects")} className='navigater'>Accounting</li>
                            <li onClick={() => Navigate("/AccountingProjects")} className='navigater'>Projects</li>

                            <li onClick={() => Navigate("/TasksTracking")} className='navigater'>Tasks & Tracking</li>

                            <li onClick={() => Navigate("/Analytics")} className='navigater'>Analytics</li>
                            <li onClick={() => Navigate("/CustomerDataAnalytics")} className='navigater'>Customer Data</li>
                            <li onClick={() => Navigate("/CustomerDataAnalytics")} className='navigater'>Analytics</li>
                        </ul>
                    </div>


                </div>




            </div>

            {/* Logistics */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><FaTruck style={{ width: '40px', height: '40px' }} />Logistics</h3>


                    <div className='navmaunu'>
                        <ul>
                            <li onClick={() => Navigate("/Inventory")} className='navigater'>Inventory</li>
                            <li onClick={() => Navigate("/PurchaseLogistics")} className='navigater'>Purchase & Logistics</li>

                            <li onClick={() => Navigate("/Suppliers")} className='navigater'>Supplies & Approved Vendors</li>
                            <li onClick={() => Navigate("/Assets")} className='navigater'>Assets Management</li>
                            <li onClick={() => Navigate("/Purchases")} className='navigater'>Purchases</li>
                        </ul>
                    </div>


                </div>




            </div>

            {/* Utilities */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><GiAutoRepair style={{ width: '40px', height: '40px' }} />Utilities</h3>


                    <div className='navmaunu'>
                        <ul>
                            <li onClick={() => Navigate("/Calendar")} className='navigater'>Calendar</li>
                            <li onClick={() => Navigate("/CustomerSupportTickets")} className='navigater'>Customer Support</li>
                            <li onClick={() => Navigate("/CustomerSupportTickets")} className='navigater'>Tickets</li>

                            <li onClick={() => Navigate("/Knowledge")} className='navigater'>Knowledge</li>
                        </ul>
                    </div>


                </div>




            </div>

            {/* Account Management */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: '20px' }}><MdSupervisorAccount style={{ width: '40px', height: '40px' }} />Account Management</h3>


                    <div className='navmaunu'>
                        <ul>

                            <li onClick={() => Navigate("/Staffacountssetting")} className='navigater'>Staff Accounts</li>
                            <li onClick={() => Navigate("/CustomerAccounts")} className='navigater'>Customer Accounts</li>


                        </ul>
                    </div>


                </div>




            </div>

            {/*Sales and  Marketing */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><MdAttachEmail style={{ width: '40px', height: '40px' }} />Sales and Marketing</h3>


                    <div className='navmaunu'>
                        <ul>
                            <li onClick={() => Navigate("/Integrations")} className='navigater'>Integrations</li>
                            <li onClick={() => Navigate("/TemplatesDocuments")} className='navigater'>Templates & Documents</li>
                            <li onClick={() => Navigate("/LeadsProposals")} className='navigater'>Leads</li>
                            <li onClick={() => Navigate("/LeadsProposals")} className='navigater'>Proposals</li>
                            <li onClick={() => Navigate("/Estimates")} className='navigater'>Estimates </li>


                        </ul>
                    </div>


                </div>




            </div>

            {/* Communication */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><MdConnectWithoutContact style={{ width: '40px', height: '40px' }} />Communication</h3>


                    <div className='navmaunu'>
                        <ul>
                            <li onClick={() => Navigate("/ChatCallSupport")} className='navigater'>Chat & Call Support</li>
                            <li onClick={() => Navigate("/EmailsSMS")} className='navigater'>Emails & SMS</li>

                        </ul>
                    </div>


                </div>




            </div>

            {/* Customer and company */}
            <div className='manu'>

                <div className='iconnav'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><FaHospitalUser style={{ width: '40px', height: '40px' }} />Customer and company </h3>


                    <div className='navmaunu'>
                        <ul>
                            <li onClick={() => Navigate("/Contacts")} className='navigater'>Contacts</li>
                            <li onClick={() => Navigate("/LoyaltyProgram")} className='navigater'>Loyalty Program</li>

                            <li onClick={() => Navigate("/Contracts")} className='navigater'>Contracts</li>

                        </ul>
                    </div>


                </div>




            </div>

            {/* Reports and Analytices */}
            <div className='manu'>

                <div onClick={() => Navigate("/Report")} className='iconnav2'> <h3 style={{ display: 'flex', alignItems: 'center', gap: '20px' }}><BsGraphUpArrow style={{ width: '40px', height: '40px' }} />Reports and Analytices</h3>





                </div>




            </div>





        </div>
    )
}

export default Sidemanu