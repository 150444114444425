import React, { useState, useEffect } from 'react'
import "./Css/staffprofiles.css"
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate } from 'react-router-dom'
import Password from 'antd/es/input/Password'
import Cookies from 'js-cookie';


function Purchases(){
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  // const token = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
  const loginemail = Cookies.get("email")

  
  const Navigate = useNavigate()
  const [viewdetails, setviewdetails] = useState(false)
  const [popup, setpopup] = useState(false)

  const [FullName, setFullName] = useState("")
  const [DateOfBirth, setDateOfBirth] = useState("")
  const [Address, setAddress] = useState("")
  const [ContactDetails, setContactDetails] = useState("")
  const [NextOfKinNameandContactNumber, setNextOfKinNameandContactNumber] = useState("")
  const [SocailSecurityNumber, setSocailSecurityNumber] = useState("")
  const [IDNumber, setIDNumber] = useState("")

  const [IDType, setIDType] = useState("")
  const [EmergancyContactNumberAndName, setEmergancyContactNumberAndName] = useState("")
  const [Title, setTitle] = useState("")
  const [Department, setDepartment] = useState("")
  const [Supervisor, setSupervisor] = useState("")
  const [WorkLocation, setWorkLocation] = useState("")
  const [StartingDate, setStartingDate] = useState("")

  const [EmploymentType, setEmploymentType] = useState("")
  const [Condition, setCondition] = useState("")

  const [Salary, setSalary] = useState("")
  const [FacebookLink, setFacebookLink] = useState("")
  const [TwitterLink, setTwitterLink] = useState("")
  const [TiktiokLink, setTiktiokLink] = useState("")
  const [InstagramLink, setInstagramLink] = useState("")
  const [youtubeLink, setyoutubeLink] = useState("")
  const [PerformanceAppraisalRelatedNotes, setPerformanceAppraisalRelatedNotes] = useState("")
  const [OfficalName, setOfficalName] = useState("")
  const [OfficalPosition, setOfficalPosition] = useState("")
  const [AppraisalDate, setAppraisalDate] = useState("")

  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")



  const [btn, setbtn] = useState(true)

  const [staffprofiles, setstaffprofiles] = useState([])





  const submitdata = async (e) => {

    await setbtn(false)
    e.preventDefault()





    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("FullName", FullName);
    urlencoded.append("DateOfBirth", DateOfBirth);
    urlencoded.append("Address", Address);
    urlencoded.append("ContactDetails", ContactDetails);
    urlencoded.append("NextOfKinNameandContactNumber", NextOfKinNameandContactNumber);
    urlencoded.append("SocailSecurityNumber", SocailSecurityNumber);
    urlencoded.append("IDType", IDType);

    urlencoded.append("EmploymentType", EmploymentType);
    urlencoded.append("Condition", Condition);


    urlencoded.append("IDNumber", IDNumber);
    urlencoded.append("EmergancyContactNumberAndName", EmergancyContactNumberAndName);
    urlencoded.append("Title", Title);
    urlencoded.append("Department", Department);
    urlencoded.append("Supervisor", Supervisor);
    urlencoded.append("WorkLocation", WorkLocation);
    urlencoded.append("StartingDate", StartingDate);
    urlencoded.append("Salary", Salary);
    urlencoded.append("FacebookLink", FacebookLink);
    urlencoded.append("TwitterLink", TwitterLink);
    urlencoded.append("TiktiokLink", TiktiokLink);
    urlencoded.append("InstagramLink", InstagramLink);
    urlencoded.append("youtubeLink", youtubeLink);
    urlencoded.append("PerformanceAppraisalRelatedNotes", PerformanceAppraisalRelatedNotes);
    urlencoded.append("OfficalName", OfficalName);
    urlencoded.append("OfficalPosition", OfficalPosition);
    urlencoded.append("AppraisalDate", AppraisalDate);
    urlencoded.append("email", email);
    urlencoded.append("password", password);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addstaff", requestOptions)
      .then(response => response.json())
      .then(result => {


        if (result === "user found") {
          message.error("Email is already in Database")
          setbtn(true)
        }
        else {

          setstaffprofiles(result)
          setpopup(false);

          setbtn(true);

          setFullName("");
          setDateOfBirth("");
          setAddress("");
          setContactDetails("");
          setNextOfKinNameandContactNumber("");
          setSocailSecurityNumber("");
          setIDNumber("");
          setEmergancyContactNumberAndName("");
          setTitle("");
          setDepartment("");
          setSupervisor("");
          setWorkLocation("");
          setStartingDate("");
          setSalary("");
          setFacebookLink("");
          setTwitterLink("");
          setTiktiokLink("");
          setInstagramLink("");
          setyoutubeLink("");
          setPerformanceAppraisalRelatedNotes("");
          setOfficalName("");
          setOfficalPosition("");
          setAppraisalDate("");
          setemail("");
          password("");

          setIDType("");
          setEmploymentType("");
          setCondition("");

      
        }

      })
      .catch(error => console.log('error', error));



  }

  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getstaffprofiles", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));















  }, [])



  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

      <Navbar />
      <div className='mainbody'>
        <Sidemanu />
        <div className='dashbordbody'>



          <div className='header'>
            <h1 className='pppheading'> Purchases</h1>


          </div>
          {viewdetails === false && <>

            <div className='Tableheader'><h3></h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>

            {staffprofiles.length !== 0 && <>
              <table className='tablep'>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>
                    <th>Date</th>
                    <th>Invoice Number</th>

                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total Price</th>
                    <th>Payment Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  {staffprofiles.map((value, index) => {

                    return (

                      <tr>

                        <td>{index + 1}</td>
               
                        <td>{value.FullName}</td>
                        <td>{value.ContactDetails}</td>
                
                        <td>{value.ContactDetails}</td>
                
                        <td>{value.IDNumber}</td>
                        <td>{value.Title}</td>
                        <td>{value.Supervisor}</td>
                        <td>{value.Supervisor}</td>
                    
                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtlstaffprofiles", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setstaffprofiles(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>
                    )
                  })}

                </tbody>
              </table>
            </>}
          </>}





        </div>


        {popup === true && <>
          <div className='popup'>
            <div className='popupbg' onClick={() => setpopup(false)}></div>
            <div className='popupcontant'>
              <h2>Enter the Details Below</h2>
              <form onSubmit={submitdata} className='newenteryform'>

                <div>  <label>Date :</label><input required value={FullName} onChange={(e) => setFullName(e.target.value)} /></div>
                <div><label>Product :</label><input required value={DateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} /></div>
                <div><label>Quantity :</label><input required value={Address} onChange={(e) => setAddress(e.target.value)} /></div>
                <div><label>Unit Price :</label><input required value={ContactDetails} onChange={(e) => setContactDetails(e.target.value)} /></div>
                <div><label>Total Price :</label><input required value={NextOfKinNameandContactNumber} onChange={(e) => setNextOfKinNameandContactNumber(e.target.value)} /></div>
                <div><label>Payment Status :</label><input required value={SocailSecurityNumber} onChange={(e) => setSocailSecurityNumber(e.target.value)} /></div>
               

             


                {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                </> : <>
                  <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                    e.preventDefault()
                    message.error("Please Wait")
                  }}>Please Wait</button>
                </>}
                <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

              </form>
            </div>
          </div>
        </>}
      </div>
    </>}  </div>
  )
}

export default Purchases