import React, { useState, useEffect } from 'react'
import "../css/index.css"
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Cookies from 'js-cookie';


function Index() {
  const Navigate = useNavigate()
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const loginemail = Cookies.get("email")

  const id = Cookies.get("id")
  const token = Cookies.get("Token")



  const login = async (e) => {


    e.preventDefault();



    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("email", email);
    urlencoded.append("password", password);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    if (email === "admin@admin.com" && password === "Data@data123") {
      message.success("logged in")
      Cookies.set('email', email, { expires: 7 }); // Expires in 7 days
      Cookies.set('id', "admin", { expires: 7 }); // Expires in 7 days
      Cookies.set('Name', "admin", { expires: 7 }); // Expires in 7 days
      Cookies.set('Token', "dskgfsdgfkgsdfkjg35464154845674987dsf@53", { expires: 7 }); // Expires in 7 days
      Navigate("/dashboard")

    } else {
      await fetch("https://backend.datawarehousegh.com:1337/login", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result === "user not found") {
            message.error("Please enter a correct Email Address")
          } else if (result === "Password is incorrect.") {
            message.error("Please enter a correct Password")
          } else if (result === "Account is disable by Admin") {
            message.error("Account is disable by Admin")


          } else {

            message.success("logged in")
            Cookies.set('email', result.email, { expires: 7 }); // Expires in 7 days
            Cookies.set('id', result._id, { expires: 7 }); // Expires in 7 days
            Cookies.set('Name', result.name, { expires: 7 }); // Expires in 7 days
            Cookies.set('Token', "dskgfsdgfkgsdfkjg35464154845674987dsf@53", { expires: 7 }); // Expires in 7 days
            Navigate("/dashboard")

          }



        })
        .catch(error => console.log('error', error));


    }


  }

  useEffect(() => {
    if (token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53") {
      Navigate("/dashboard")
    }
  }, [])

  return (
    <div className='mainindexpages'>



      <div className='loginbox'>

        <img src='logo192.png' alt='' width={"100%"} />
        <h2>Login</h2>

        <form className='loginform' onSubmit={login}>


          <input placeholder='Please Enter your Email' type='email' value={email} required onChange={(e) => setemail(e.target.value)} />
          <input type='password' placeholder='Please enter the Password' value={password} required onChange={(e) => setpassword(e.target.value)} />

          <button>Login</button>


        </form>


      </div>










    </div>
  )
}

export default Index