import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import "./Css/Recruitment.css"



function StaffDetailsRecruitment() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")



  const Navigate = useNavigate()
  const [viewdetails, setviewdetails] = useState(false)
  const [popup, setpopup] = useState(false)

  const [JobTitle, setJobTitle] = useState("")
  const [Shift, setShift] = useState("")
  const [Discription, setDiscription] = useState("")
  const [Condition, setCondition] = useState("")
  const [Location, setLocation] = useState("")

  const [btn, setbtn] = useState(true)

  const [jobs, setjobs] = useState([])

  const [staffprofiles, setstaffprofiles] = useState([])



  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getstaffprofilescareer", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));



    fetch("https://backend.datawarehousegh.com:1337/getjob", requestOptions)
      .then(response => response.json())
      .then(result => setjobs(result))
      .catch(error => console.log('error', error));














  }, [])

  var count = 0;



  const submitdata = async (e) => {

    await setbtn(false)
    e.preventDefault()


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("jobtitle", JobTitle);

    urlencoded.append("shift", Shift);
    urlencoded.append("Discription", Discription);
    urlencoded.append("Condition", Condition);
    urlencoded.append("Location", Location);



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addjob", requestOptions)
      .then(response => response.json())
      .then(result => {



        setjobs(result)
        setpopup(false);

        setbtn(true);




      })
      .catch(error => console.log('error', error));



  }


  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>







            <div className='header'>
              <h1 className='pppheading'> Recruitment</h1>


            </div>


            <div className='Tableheader'><h2>Jobs</h2><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>



            <div className='jobsdiv'>

              {jobs.map((value) => {

                return (<>
                  <div className='joblist'>
                    <h3>job title  :   {value.jobtitle}</h3>
                    <h4>Location   :   {value.Location}</h4>
                    <h4>full or part :  {value.Condition}</h4>
                    <h4>Shift :  {value.shift}</h4>
                    <div> <h4>Discription</h4>
                      <p> {value.Discription}</p>
                    </div>
                    <button className='formalbtn' onClick={() => {
                      navigator.clipboard.writeText("https://portal.datawarehousegh.com/career")
                        .then(() => {
                          message.success("link copied to clipboard");
                        })
                        .catch(err => {
                          console.error("Failed to copy text: ", err);
                        });
                    }}>Copy Link</button>
                    <button className='formalbtncncl'

                      onClick={async () => {

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                        var urlencoded = new URLSearchParams();
                        urlencoded.append("_id", value._id);


                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: 'follow'
                        };

                        await fetch("https://backend.datawarehousegh.com:1337/dtljobs", requestOptions)
                          .then(response => response.json())
                          .then(result => {
                            setjobs(result)



                          })
                          .catch(error => console.log('error', error));


                      }}

                    >Delete</button>
                  </div>

                </>)
              })}




            </div>





            <div className='Tableheader'><h3>New Entery Forms</h3></div>

            {staffprofiles.length !== 0 && <>
              <table className='tablep'>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>
                    <th>DOB</th>
                    <th>Name</th>

                    <th>Contact Number</th>
                    <th>SSN</th>
                    {/* <th>ID Type</th> */}
                    <th>ID Number</th>
                    <th>Applied For</th>

                    <th>Emergency Contact NO. </th>
                    <th>Address</th>
                    <th>Details</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  {staffprofiles.map((value, index) => {

                    return (<>
                      {value.status === "Newform" && <>
                        <tr>

                          <td>{index + 1}</td>
                          <td>{value.DateOfBirth}</td>
                          <td>{value.FullName}</td>
                          <td>{value.ContactDetails}</td>
                          <td>{value.SocailSecurityNumber}</td>
                          {/* <td>{value.}</td> */}
                          <td>{value.IDNumber}</td>
                          <td>{value.jobtitle}</td>
                          <td>{value.EmergancyContactNumberAndName}</td>
                          <td>{value.Address}</td>

                          <td><a href='#' onClick={(e) => {

                            e.preventDefault()

                            Navigate("/StaffDetailsRecruitment/view/" + value._id)
                            // setviewdetails(true)

                          }}>View</a></td>
                          <td>
                            <AiFillDelete className='hovar' onClick={async () => {

                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", value._id);


                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };

                              await fetch("https://backend.datawarehousegh.com:1337/dtlstaffprofilescareer", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                  setstaffprofiles(result)



                                })
                                .catch(error => console.log('error', error));


                            }} style={{ color: "red", width: '25px', height: '25px' }} />
                          </td>

                        </tr>
                      </>}</>)
                  })}

                </tbody>
              </table>
            </>}


            <div className='Tableheader'><h3>Interview List</h3></div>

            {staffprofiles.length !== 0 && <>
              <table className='tablep'>
                <thead className='tablephead'>
                  <tr>
                    <th>S NO.</th>
                    <th>DOB</th>
                    <th>Name</th>

                    <th>Contact Number</th>
                    <th>SSN</th>
                    {/* <th>ID Type</th> */}
                    <th>ID Number</th>
                    <th>Applied For</th>
                    <th>Emergency Contact NO. </th>
                    <th>Address</th>
                    <th>Details</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  {staffprofiles.map((value, index) => {

                    return (<>
                      {value.status === "interview" && <>
                        <tr>

                          <td>{index + 1}</td>
                          <td>{value.DateOfBirth}</td>
                          <td>{value.FullName}</td>
                          <td>{value.ContactDetails}</td>
                          <td>{value.SocailSecurityNumber}</td>
                          {/* <td>{value.}</td> */}
                          <td>{value.IDNumber}</td>
                          <td>{value.jobtitle}</td>
                       
                          <td>{value.EmergancyContactNumberAndName}</td>
                          <td>{value.Address}</td>

                          <td><a href='#' onClick={(e) => {

                            e.preventDefault()

                            Navigate("/StaffDetailsRecruitment/view/" + value._id)
                            // setviewdetails(true)

                          }}>View</a></td>
                          <td>
                            <AiFillDelete className='hovar' onClick={async () => {

                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", value._id);


                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };

                              await fetch("https://backend.datawarehousegh.com:1337/dtlstaffprofilescareer", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                  setstaffprofiles(result)



                                })
                                .catch(error => console.log('error', error));


                            }} style={{ color: "red", width: '25px', height: '25px' }} />
                          </td>

                        </tr>
                      </>}</>)
                  })}

                </tbody>
              </table>
            </>}










          </div>



          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>
                <form onSubmit={submitdata} className='newenteryform'>

                  <div>  <label>Job Title :</label><input required value={JobTitle} onChange={(e) => setJobTitle(e.target.value)} /></div>
                  <div><label>Location :</label><input required value={Location} onChange={(e) => setLocation(e.target.value)} /></div>

                  <div><label>Condition :</label>


                    <select id="Condition" name="Condition" onChange={(e) => setCondition(e.target.value)}>
                      <option disabled selected> select option</option>
                      <option value="Part Time">Part Time</option>
                      <option value="Full Time">Full Time </option>
                      <option value="Part Time/Full Time">Part Time/Full Time</option>

                    </select>
                  </div>

                  <div><label>Shift :</label><input required value={Shift} onChange={(e) => setShift(e.target.value)} /></div>
                  <h2>Discription</h2>
                  <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} value={Discription} onChange={(e) => setDiscription(e.target.value)} /></div>





                  {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                  </> : <>
                    <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                      e.preventDefault()
                      message.error("Please Wait")
                    }}>Please Wait</button>
                  </>}
                  <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

                </form>
              </div>
            </div>
          </>}



        </div>
      </>}  </div>
  )
}

export default StaffDetailsRecruitment