import React, { useEffect, useState } from 'react'
import { message } from 'antd'

import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";



function Recuimentform() {


    const { id } = useParams();
    const [FullName, setFullName] = useState("")
    const [DateOfBirth, setDateOfBirth] = useState("")
    const [Address, setAddress] = useState("")
    const [ContactDetails, setContactDetails] = useState("")
    const [NextOfKinNameandContactNumber, setNextOfKinNameandContactNumber] = useState("")
    const [SocailSecurityNumber, setSocailSecurityNumber] = useState("")
    const [IDNumber, setIDNumber] = useState("")
    const [EmergancyContactNumberAndName, setEmergancyContactNumberAndName] = useState("")
    const [email, setemail] = useState("")

    const [FacebookLink, setFacebookLink] = useState("")
    const [TwitterLink, setTwitterLink] = useState("")
    const [TiktiokLink, setTiktiokLink] = useState("")
    const [InstagramLink, setInstagramLink] = useState("")
    const [youtubeLink, setyoutubeLink] = useState("")
    const [PerformanceAppraisalRelatedNotes, setPerformanceAppraisalRelatedNotes] = useState("")

    const [btn, setbtn] = useState(true)

    const [Img, setImg] = useState()
    const [cv, setcv] = useState()




    useEffect(() => {


        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'GET',

            redirect: 'follow'
        };

        fetch("https://backend.datawarehousegh.com:1337/getjob", requestOptions)
            .then(response => response.json())
            .then(result => setjobs(result))
            .catch(error => console.log('error', error));







    }, [])

    const Navigate = useNavigate()
    useEffect(() => {


        if (id) {
            const matchedJob = jobs.find(job => job._id === id);
            if (matchedJob) {
                setjobfor(matchedJob);
            } else {
                setjobfor([]);
            }
        } else {
            setjobfor([])
        }





    },)


    const submitdata = async (e) => {

        await setbtn(false)
        e.preventDefault()


        const Carimageid2 = v4();
        const imgRef2 = ref(imageDb, `profiles/${Carimageid2}`)
        await uploadBytes(imgRef2, Img)
        const imgUrl = await getDownloadURL(imgRef2);


        console.log(imgUrl)


        const Carimageid = v4();
        const ex = cv.name.split('.')[1]
        const imgRef = ref(imageDb, `files/${Carimageid}.${ex}`)
        await uploadBytes(imgRef, cv)
        const cvUrl = await getDownloadURL(imgRef);


        console.log(cvUrl)




        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("FullName", FullName);
        urlencoded.append("DateOfBirth", DateOfBirth);
        urlencoded.append("Address", Address);
        urlencoded.append("ContactDetails", ContactDetails);
        urlencoded.append("NextOfKinNameandContactNumber", NextOfKinNameandContactNumber);
        urlencoded.append("SocailSecurityNumber", SocailSecurityNumber);
        urlencoded.append("IDNumber", IDNumber);
        urlencoded.append("EmergancyContactNumberAndName", EmergancyContactNumberAndName);
        urlencoded.append("email", email);

        urlencoded.append("FacebookLink", FacebookLink);
        urlencoded.append("TwitterLink", TwitterLink);
        urlencoded.append("TiktiokLink", TiktiokLink);
        urlencoded.append("InstagramLink", InstagramLink);
        urlencoded.append("youtubeLink", youtubeLink);
        urlencoded.append("PerformanceAppraisalRelatedNotes", PerformanceAppraisalRelatedNotes);
        urlencoded.append("cv", cvUrl);
        urlencoded.append("profilepic", imgUrl);

        urlencoded.append("jobtitle", jobfor.jobtitle);

        urlencoded.append("shift", jobfor.shift);
        urlencoded.append("Discription", jobfor.Discription);
        urlencoded.append("Condition", jobfor.Condition);
        urlencoded.append("Location", jobfor.Location);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        await fetch("https://backend.datawarehousegh.com:1337/addstaffcareer", requestOptions)
            .then(response => response.json())
            .then(result => {


                setFullName("");
                setDateOfBirth("");
                setAddress("");
                setContactDetails("");
                setNextOfKinNameandContactNumber("");
                setSocailSecurityNumber("");
                setIDNumber("");
                setEmergancyContactNumberAndName("");
                setemail("");


                setFacebookLink("");
                setTwitterLink("");
                setTiktiokLink("");
                setInstagramLink("");
                setyoutubeLink("");
                setPerformanceAppraisalRelatedNotes("");

                Navigate("/career")
                                


                setbtn(true)
                message.success("We have received your data and appreciate your prompt response. Rest assured, our team will review the information diligently, and we will make every effort to get back to you as soon as possible. Thank you for your patience.")


            })
            .catch(error => console.log('error', error));



    }


    const [jobfor, setjobfor] = useState([])
    const [jobs, setjobs] = useState([])


    return (
        <div>



            <div className='dashbordbody' style={{ marginBottom: '100px' }}>
                <h1 style={{ borderLeft: "5px solid #0587EF", textAlign: 'left', borderRadius: '5px', color: "rgb(70, 70, 70)", paddingLeft: "10px", alignSelf: "baseline" }}>
                    CAREER WITH US</h1>


                <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>

                    We are Data Warehouse Gh. One team with one big passion. Internet Connectivity, Digitalisation and Technology. We sell it, we deliver it, we install it, We Connect People, we support it, we fix it. And, most important of all, we bring a human touch to tech to make the magic happen!
                    <br /> <br /> As a company, we want to be proud of what customers say about the services they receive from us. Online, in-store or in their homes, we want to go the extra mile. And it’s all down to our people, - the team - capable and committed colleagues, learning together, growing together, making us amazing and celebrating the wins together.
                    <br /><br /> We know our people – the ones who sell, the ones who serve, the ones who support – are the magic ingredient in our success journey. They’re the ones who will make amazing things happen for our customers, time and time again. That’s why everything we’re doing is so our team can keep doing their thing, like introducing hybrid working for suitable roles so that our colleagues can get the best of both the virtual and in-person working experience. Put simply, we’re creating a clearer, simpler, faster place to work, where all our colleagues have the support they need to perform at their very best.

                </div>






                {jobfor.length === 0 && <>
                    <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray', textAlign: 'left' }}>
                        Available Jobs</h2>

                    <div className='jobsdiv'>

                        {jobs.map((value) => {

                            return (<>
                                <div className='joblist'>
                                    <h3>job title  :   {value.jobtitle}</h3>
                                    <h4>Location   :   {value.Location}</h4>
                                    <h4>full or part :  {value.Condition}</h4>
                                    <h4>Shift :  {value.shift}</h4>
                                    <div> <h4>Discription</h4>
                                        <p> {value.Discription}</p>
                                    </div>
                                    <button className='formalbtn' onClick={() => {
                                        Navigate("/career/" + value._id)
                                    }}>Apply Now</button>

                                </div>

                            </>)
                        })}




                    </div>
                </>}

                <div style={{ marginBottom: '50px' }}></div>


                {jobfor.length !== 0 && <>

                    <h2 style={{ borderRadius: '5px', paddingLeft: "15px", alignSelf: "baseline", margin: '0', color: 'gray', textAlign: 'left' }}>
                        {jobfor.jobtitle}</h2>


                    <div style={{ width: '90%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingLeft: '17px', textAlign: 'justify', color: 'gray', marginBottom: '50px' }}>
                        Applicants, please take note of the following criteria when applying to be the part of datawarehouse.

                        <div> <h4>Discription</h4>
                            <p style={{ margin: '0', marginBottom: '20px' }}> {jobfor.Discription}</p>
                        </div>
                        <li>     Location   :   {jobfor.Location}
                        </li>
                        <li>   {jobfor.Condition}</li>
                        <li>   Shift :  {jobfor.shift}</li>
                    </div>
                    <form onSubmit={submitdata} className='newenteryform'>

                        <div>  <label>Full Name :</label><input required value={FullName} onChange={(e) => setFullName(e.target.value)} /></div>
                        <div><label>Date Of Birth :</label><input required value={DateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} /></div>
                        <div><label>email :</label><input required value={email} onChange={(e) => setemail(e.target.value)} /></div>

                        <div><label>Address :</label><input required value={Address} onChange={(e) => setAddress(e.target.value)} /></div>
                        <div><label>Contact Details :</label><input required value={ContactDetails} onChange={(e) => setContactDetails(e.target.value)} /></div>
                        <div><label>Next Of Kin Name and Contact Number :</label><input required value={NextOfKinNameandContactNumber} onChange={(e) => setNextOfKinNameandContactNumber(e.target.value)} /></div>
                        <div><label>Socail Security Number :</label><input required value={SocailSecurityNumber} onChange={(e) => setSocailSecurityNumber(e.target.value)} /></div>
                        <div><label>ID Type :</label>

                            <select id="id" name="id">
                                <option disabled selected> select option</option>
                                <option value="volvo">Passport</option>
                                <option value="saab">Ecowas Card</option>
                                <option value="fiat">Voters Card</option>

                            </select>
                        </div>


                        <div><label>ID Number :</label><input required value={IDNumber} onChange={(e) => setIDNumber(e.target.value)} /></div>
                        <div><label>Emergancy Contact Number And Name  :</label><input required value={EmergancyContactNumberAndName} onChange={(e) => setEmergancyContactNumberAndName(e.target.value)} /></div>
                        <div><label>Profile Picture :</label><input accept="image/*" onChange={(e) => setImg(e.target.files[0])} required type='file' /></div>
                        <div><label>Please upload your resume  :</label><input onChange={(e) => setcv(e.target.files[0])} required type='file' /></div>



                        <h2>Socail Media Links</h2>
                        <div><label>Facebook Link :</label><input required value={FacebookLink} onChange={(e) => setFacebookLink(e.target.value)} /></div>
                        <div><label>Twitter(X) Link :</label><input required value={TwitterLink} onChange={(e) => setTwitterLink(e.target.value)} /></div>
                        <div><label>Tiktiok Link :</label><input required value={TiktiokLink} onChange={(e) => setTiktiokLink(e.target.value)} /></div>
                        <div><label>Instagram Link :</label><input required value={InstagramLink} onChange={(e) => setInstagramLink(e.target.value)} /></div>
                        <div><label>youtube Link :</label><input required value={youtubeLink} onChange={(e) => setyoutubeLink(e.target.value)} /></div>

                        <h2>Why WE Should Select YOU?</h2>
                        <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} value={PerformanceAppraisalRelatedNotes} onChange={(e) => setPerformanceAppraisalRelatedNotes(e.target.value)} /></div>

                        {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                        </> : <>
                            <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                                e.preventDefault()
                                message.error("Please Wait")
                            }}>Please Wait</button>
                        </>}

                    </form>

                </>}


            </div>












        </div >
    )
}

export default Recuimentform