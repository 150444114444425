import React, { useState, useEffect } from 'react'
import "./Css/staffprofiles.css"
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate } from 'react-router-dom'
import Password from 'antd/es/input/Password'
import Cookies from 'js-cookie';
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

function StaffProfiles() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  // const token = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
  const loginemail = Cookies.get("email")


  const Navigate = useNavigate()
  const [viewdetails, setviewdetails] = useState(false)
  const [popup, setpopup] = useState(false)

  const [FullName, setFullName] = useState("")
  const [DateOfBirth, setDateOfBirth] = useState("")
  const [Address, setAddress] = useState("")
  const [ContactDetails, setContactDetails] = useState("")
  const [NextOfKinNameandContactNumber, setNextOfKinNameandContactNumber] = useState("")
  const [SocailSecurityNumber, setSocailSecurityNumber] = useState("")
  const [IDNumber, setIDNumber] = useState("")

  const [IDType, setIDType] = useState("")
  const [EmergancyContactNumberAndName, setEmergancyContactNumberAndName] = useState("")
  const [Title, setTitle] = useState("")
  const [Department, setDepartment] = useState("")
  const [Supervisor, setSupervisor] = useState("")
  const [WorkLocation, setWorkLocation] = useState("")
  const [StartingDate, setStartingDate] = useState("")

  const [EmploymentType, setEmploymentType] = useState("")
  const [Condition, setCondition] = useState("")

  const [Salary, setSalary] = useState("")
  const [FacebookLink, setFacebookLink] = useState("")
  const [TwitterLink, setTwitterLink] = useState("")
  const [TiktiokLink, setTiktiokLink] = useState("")
  const [InstagramLink, setInstagramLink] = useState("")
  const [youtubeLink, setyoutubeLink] = useState("")
  const [PerformanceAppraisalRelatedNotes, setPerformanceAppraisalRelatedNotes] = useState("")
  const [OfficalName, setOfficalName] = useState("")
  const [OfficalPosition, setOfficalPosition] = useState("")
  const [AppraisalDate, setAppraisalDate] = useState("")

  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")



  const [btn, setbtn] = useState(true)

  const [staffprofiles, setstaffprofiles] = useState([])

  const [Img, setImg] = useState()
  const [cv, setcv] = useState()



  const submitdata = async (e) => {

    await setbtn(false)
    e.preventDefault()


    const Carimageid2 = v4();
    const imgRef2 = ref(imageDb, `profiles/${Carimageid2}`)
    await uploadBytes(imgRef2, Img)
    const imgUrl = await getDownloadURL(imgRef2);


    console.log(imgUrl)


    const Carimageid = v4();
    const ex = cv.name.split('.')[1]
    const imgRef = ref(imageDb, `files/${Carimageid}.${ex}`)
    await uploadBytes(imgRef, cv)
    const cvUrl = await getDownloadURL(imgRef);


    console.log(cvUrl)
  


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("FullName", FullName);
    urlencoded.append("DateOfBirth", DateOfBirth);
    urlencoded.append("Address", Address);
    urlencoded.append("ContactDetails", ContactDetails);
    urlencoded.append("NextOfKinNameandContactNumber", NextOfKinNameandContactNumber);
    urlencoded.append("SocailSecurityNumber", SocailSecurityNumber);
    urlencoded.append("IDType", IDType);

    urlencoded.append("EmploymentType", EmploymentType);
    urlencoded.append("Condition", Condition);


    urlencoded.append("IDNumber", IDNumber);
    urlencoded.append("EmergancyContactNumberAndName", EmergancyContactNumberAndName);
    urlencoded.append("Title", Title);
    urlencoded.append("Department", Department);
    urlencoded.append("Supervisor", Supervisor);
    urlencoded.append("WorkLocation", WorkLocation);
    urlencoded.append("StartingDate", StartingDate);
    urlencoded.append("Salary", Salary);
    urlencoded.append("FacebookLink", FacebookLink);
    urlencoded.append("TwitterLink", TwitterLink);
    urlencoded.append("TiktiokLink", TiktiokLink);
    urlencoded.append("InstagramLink", InstagramLink);
    urlencoded.append("youtubeLink", youtubeLink);
    urlencoded.append("PerformanceAppraisalRelatedNotes", PerformanceAppraisalRelatedNotes);
    urlencoded.append("OfficalName", OfficalName);
    urlencoded.append("OfficalPosition", OfficalPosition);
    urlencoded.append("AppraisalDate", AppraisalDate);
    urlencoded.append("email", email);
    urlencoded.append("password", password);
    urlencoded.append("cv", cvUrl);
    urlencoded.append("profilepic", imgUrl);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addstaff", requestOptions)
      .then(response => response.json())
      .then(result => {


        if (result === "user found") {
          message.error("Email is already in Database")
          setbtn(true)
        }
        else {

          setstaffprofiles(result)
          setpopup(false);

          setbtn(true);

          setFullName("");
          setDateOfBirth("");
          setAddress("");
          setContactDetails("");
          setNextOfKinNameandContactNumber("");
          setSocailSecurityNumber("");
          setIDNumber("");
          setEmergancyContactNumberAndName("");
          setTitle("");
          setDepartment("");
          setSupervisor("");
          setWorkLocation("");
          setStartingDate("");
          setSalary("");
          setFacebookLink("");
          setTwitterLink("");
          setTiktiokLink("");
          setInstagramLink("");
          setyoutubeLink("");
          setPerformanceAppraisalRelatedNotes("");
          setOfficalName("");
          setOfficalPosition("");
          setAppraisalDate("");
          setemail("");
          password("");

          setIDType("");
          setEmploymentType("");
          setCondition("");


        }

      })
      .catch(error => console.log('error', error));



  }

  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getstaffprofiles", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));















  }, [])



  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>



            <div className='header'>
              <h1 className='pppheading'> Staff Profiles</h1>


            </div>
            {viewdetails === false && <>

              <div className='Tableheader'><h3>******* Department</h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>

              {staffprofiles.length !== 0 && <>
                <table className='tablep'>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>
                      <th>DOB</th>
                      <th>Name</th>

                      <th>Contact Number</th>
                      <th>SSN</th>
                      {/* <th>ID Type</th> */}
                      <th>ID Number</th>
                      <th>Job Title</th>
                      <th>Supervisor</th>
                      <th>Salary</th>
                      <th>Emergency Contact NO. </th>
                      <th>Address</th>
                      <th>Details</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>

                    {staffprofiles.map((value, index) => {

                      return (

                        <tr>

                          <td>{index + 1}</td>
                          <td>{value.DateOfBirth}</td>
                          <td>{value.FullName}</td>
                          <td>{value.ContactDetails}</td>
                          <td>{value.SocailSecurityNumber}</td>
                          {/* <td>{value.}</td> */}
                          <td>{value.IDNumber}</td>
                          <td>{value.Title}</td>
                          <td>{value.Supervisor}</td>
                          <td>{value.Salary}</td>
                          <td>{value.EmergancyContactNumberAndName}</td>
                          <td>{value.Address}</td>

                          <td><a href='#' onClick={(e) => {

                            e.preventDefault()

                            Navigate("/StaffProfiles/view/" + value._id)
                            // setviewdetails(true)

                          }}>View</a></td>
                          <td>
                            <AiFillDelete className='hovar' onClick={async () => {

                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", value._id);


                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };

                              await fetch("https://backend.datawarehousegh.com:1337/dtlstaffprofiles", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                  setstaffprofiles(result)



                                })
                                .catch(error => console.log('error', error));


                            }} style={{ color: "red", width: '25px', height: '25px' }} />
                          </td>

                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </>}
            </>}





          </div>


          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>
                <form onSubmit={submitdata} className='newenteryform'>

                  <div>  <label>Full Name :</label><input required value={FullName} onChange={(e) => setFullName(e.target.value)} /></div>
                  <div><label>Date Of Birth :</label><input required value={DateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} /></div>
                  <div><label>Address :</label><input required value={Address} onChange={(e) => setAddress(e.target.value)} /></div>
                  <div><label>Contact Details :</label><input required value={ContactDetails} onChange={(e) => setContactDetails(e.target.value)} /></div>
                  <div><label>Next Of Kin Name and Contact Number :</label><input required value={NextOfKinNameandContactNumber} onChange={(e) => setNextOfKinNameandContactNumber(e.target.value)} /></div>
                  <div><label>Socail Security Number :</label><input required value={SocailSecurityNumber} onChange={(e) => setSocailSecurityNumber(e.target.value)} /></div>
                  <div><label>ID Type :</label>

                    <select id="id" name="id" onChange={(e) => setIDType(e.target.value)}>
                      <option disabled selected> select option</option>
                      <option value="Passport">Passport</option>
                      <option value="Ecowas Card">Ecowas Card</option>
                      <option value="Voters Card">Voters Card</option>

                    </select>
                  </div>


                  <div><label>ID Number :</label><input required value={IDNumber} onChange={(e) => setIDNumber(e.target.value)} /></div>
                  <div><label>Emergancy Contact Number And Name  :</label><input required value={EmergancyContactNumberAndName} onChange={(e) => setEmergancyContactNumberAndName(e.target.value)} /></div>

                  <div><label>Profile Picture :</label><input accept="image/*" onChange={(e) => setImg(e.target.files[0])} required type='file' /></div>

                  <h2>Job Imformation</h2>
                  <div><label>Job Title :</label><input required value={Title} onChange={(e) => setTitle(e.target.value)} /></div>
                  <div><label>Department :</label><input required value={Department} onChange={(e) => setDepartment(e.target.value)} /></div>
                  <div><label>Core Responsibilities :</label><input required /></div>

                  <div><label>Supervisor :</label><input required value={Supervisor} onChange={(e) => setSupervisor(e.target.value)} /></div>
                  <div><label>Work Location :</label><input required value={WorkLocation} onChange={(e) => setWorkLocation(e.target.value)} /></div>
                  <div><label>Starting Date :</label><input required value={StartingDate} onChange={(e) => setStartingDate(e.target.value)} /></div>
                  <div><label>Employment Type :</label>

                    <select id="type" name="type" onChange={(e) => setEmploymentType(e.target.value)}>
                      <option disabled selected> select option</option>
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                      <option value="Commission Only">Commission Only</option>

                    </select>
                  </div>
                  <div><label>Condition :</label>

                    <select id="Condition" name="Condition" onChange={(e) => setCondition(e.target.value)}>
                      <option disabled selected> select option</option>
                      <option value="Probation">Probation</option>
                      <option value="Extended Probation">Extended Probation </option>
                      <option value="Confirmed">Confirmed</option>

                    </select>
                  </div>
                  <div><label>Gross Salary :</label><input required value={Salary} onChange={(e) => setSalary(e.target.value)} /></div>
                  <div><label>CV :</label><input onChange={(e) => setcv(e.target.files[0])} required type='file' /></div>

                  <h2>Socail Media Links</h2>
                  <div><label>Facebook Link :</label><input required value={FacebookLink} onChange={(e) => setFacebookLink(e.target.value)} /></div>
                  <div><label>Twitter(X) Link :</label><input required value={TwitterLink} onChange={(e) => setTwitterLink(e.target.value)} /></div>
                  <div><label>Tiktiok Link :</label><input required value={TiktiokLink} onChange={(e) => setTiktiokLink(e.target.value)} /></div>
                  <div><label>Instagram Link :</label><input required value={InstagramLink} onChange={(e) => setInstagramLink(e.target.value)} /></div>
                  <div><label>youtube Link :</label><input required value={youtubeLink} onChange={(e) => setyoutubeLink(e.target.value)} /></div>

                  <h2>Performance Appraisal Related Notes</h2>
                  <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} value={PerformanceAppraisalRelatedNotes} onChange={(e) => setPerformanceAppraisalRelatedNotes(e.target.value)} /></div>
                  <div><label>Offical's Name :</label><input required value={OfficalName} onChange={(e) => setOfficalName(e.target.value)} /></div>
                  <div><label>Offical's Position :</label><input required value={OfficalPosition} onChange={(e) => setOfficalPosition(e.target.value)} /></div>
                  <div><label>Appraisal's Date :</label><input required value={AppraisalDate} onChange={(e) => setAppraisalDate(e.target.value)} /></div>


                  <h2>login credentials</h2>
                  <div><label>Email :</label><input required value={email} onChange={(e) => setemail(e.target.value)} /></div>
                  <div><label>Passpord :</label><input type='password' required value={password} onChange={(e) => setpassword(e.target.value)} /></div>



                  {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                  </> : <>
                    <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                      e.preventDefault()
                      message.error("Please Wait")
                    }}>Please Wait</button>
                  </>}
                  <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

                </form>
              </div>
            </div>
          </>}
        </div>
      </>}  </div>
  )
}

export default StaffProfiles