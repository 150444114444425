import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import "./Css/InvoicesPayments.css"
import { message } from 'antd'


function Creditnotes() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")
  const [popup, setpopup] = useState(false)
  const [customer, setcustomer] = useState([])
  const [invoice, setinvoice] = useState([])
  const [device, setdevice] = useState([])
  const [staffprofiles, setstaffprofiles] = useState([])
  const [btn, setbtn] = useState(true)

  const [Note, setNote] = useState("")
  const [Ammount, setAmmount] = useState("")


  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getcreaditnotes", requestOptions)
      .then(response => response.json())
      .then(result => setinvoice(result))
      .catch(error => console.log('error', error));





    fetch("https://backend.datawarehousegh.com:1337/getCustomerprofiles", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));












  }, [])







  const submitdata = async (e) => {

    // await setbtn(false)
    e.preventDefault()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("note", Note);
    urlencoded.append("Ammount", Ammount);
    urlencoded.append("CustomerID", customer._id);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };


    await fetch("https://backend.datawarehousegh.com:1337/addcreaditnote", requestOptions)
      .then(response => response.json())
      .then(result => {

        setinvoice(result)

        setAmmount("")
        setNote("")
        setcustomer("")


        setpopup(false)

        setbtn(true)
      })
      .catch(error => console.log('error', error));




  }










  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>


            <div className='header'>
              <h1 className='pppheading'> Credit Notes</h1>


            </div>
            <div className='Tableheader'><h3>******* Department</h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>


            <div style={{ marginTop: '50px' }}></div>

            {invoice.map((value) => {

              var CustomerName
              var Email
              var ID
              var Address


            

              staffprofiles.map((value2) => {

                if (value2._id === value.CustomerID) {

                  CustomerName = value2.FullName
                  Email = value2.email
                  ID = value2.IDNumber
                  Address = value2.Address

                }

              })

           

              return (<>
                <div>
                  <div className='invoicebox'>
                    <div className='invoiceinside'>
                      <div>
                        <h3>{CustomerName}</h3>
                        <h3>{value.CustomerID}</h3>
                        <p>{Email}</p>
                        <p>{ID}</p>
                        <p>{Address}</p>
                        <p>{Email}</p>
                      </div>


                      <div>

                        <h3>Note</h3>
                        <p>{value.note}</p>
                        

                      </div>

                      <div>

                        <h3>Amount </h3>
                        <p> {value.Ammount} </p>
                        

                      </div>
                    </div>
                    <div>
                      <h3> Date: {value.timestamp}</h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <button className='formalbtncncl'>Delete</button>
                      <button className='formalbtn'>Download</button>

                    </div>
                  </div>

                </div>
              </>)
            })}
          </div>


          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>
                <form onSubmit={submitdata} className='newenteryform'>

                  <div>  <label>Note  :</label><input required value={Note} onChange={(e) => setNote(e.target.value)} /></div>
                  <div><label>Ammount :</label><input required value={Ammount} onChange={(e) => setAmmount(e.target.value)} /></div>

                  <h2>Job Imformation</h2>

                  <div> Customer</div>

                  <div className='customerassignbox'>


                    {staffprofiles.map((value) => <>
                      <div className='customerassigncard' onClick={() => {

                        setcustomer(value)
                      }}>
                        <h3>Name : {value.FullName}</h3>
                        <h3>Email : {value.email}</h3>
                        <h3>ID Number : {value.IDNumber}</h3>
                      </div>
                    </>)}
                  </div>




                  {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                  </> : <>
                    <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                      e.preventDefault()
                      message.error("Please Wait")
                    }}>Please Wait</button>
                  </>}
                  <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

                </form>
              </div>
            </div>
          </>}

        </div>
      </>}  </div>
  )
}

export default Creditnotes