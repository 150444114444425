import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Index from './pages';

import Dashboard from './pages/dashboard'
import Report from './pages/report'


import StaffProfiles from "./sidemanupages/StaffProfiles"
import StaffProfiles2 from "./sidemanupages/StaffProfiles copy"
import StaffProfiles3 from "./sidemanupages/StaffDetailsRecruitment copy"

import CustomerAccounts from "./sidemanupages/CustomerAccounts"
import FinancialDetails from "./sidemanupages/FinancialDetails"
import InvoicesPayments from "./sidemanupages/InvoicesPayments"
import Creditnotes from "./sidemanupages/Creditnotes"
import Commissions from "./sidemanupages/Commissions"
import PayslipSalarySystem from "./sidemanupages/PayslipSalarySystem"
import Expenses from "./sidemanupages/Expenses"
import TasksTracking from "./sidemanupages/TasksTracking"
import Subscriptions from "./sidemanupages/Subscriptions"
import LeadsProposals from "./sidemanupages/LeadsProposals"
import Estimates from "./sidemanupages/Estimates"
import Integrations from "./sidemanupages/Integrations"
import TemplatesDocuments from "./sidemanupages/TemplatesDocuments"
import ChatCallSupport from "./sidemanupages/ChatCallSupport"
import EmailsSMS from "./sidemanupages/EmailsSMS"
import Contacts from "./sidemanupages/Contacts"
import LoyaltyProgram from "./sidemanupages/LoyaltyProgram"
import Contracts from "./sidemanupages/Contracts"
import StaffDetailsRecruitment from "./sidemanupages/StaffDetailsRecruitment"
import AccountingProjects from "./sidemanupages/AccountingProjects"
import CustomerSupportTickets from "./sidemanupages/CustomerSupportTickets"
import PurchaseLogistics from "./sidemanupages/PurchaseLogistics"
import Analytics from "./sidemanupages/Analytics"
import CustomerDataAnalytics from "./sidemanupages/CustomerDataAnalytics"
import Inventory from "./sidemanupages/Inventory"
import Suppliers from "./sidemanupages/Suppliers"
import Assets from "./sidemanupages/Assets"
import Purchases from "./sidemanupages/Purchases"
import Calendar from "./sidemanupages/Calendar"
import Knowledge from "./sidemanupages/Knowledge"
import Recuimentform from './pages/recuimentform';
import Staffacountssetting from './sidemanupages/staffacountssetting';
import Payslip from './sidemanupages/payslip';







function ScrollToTop() {
  const { pathname } = useLocation();



  useEffect(() => {
    window.scrollTo(0, 0);




  }, [pathname]);

  return null;
}






function App() {



  return (
    <div>

      <BrowserRouter>
        <ScrollToTop />


        <Routes>

          <Route path='/' element={<Index />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/Report' element={<Report />} />

          <Route path='/StaffProfiles' element={<StaffProfiles />} />
          <Route path='/StaffProfiles/view/:id' element={<StaffProfiles2 />} />
         
          <Route path='/CustomerAccounts' element={<CustomerAccounts />} />
          <Route path='/FinancialDetails' element={<FinancialDetails />} />
          <Route path='/InvoicesPayments' element={<InvoicesPayments />} />
          <Route path='/Creditnotes' element={< Creditnotes />} />
          <Route path='/Commissions' element={< Commissions />} />
          <Route path='/payslip' element={< Payslip />} />
          <Route path='/PayslipSalarySystem' element={< PayslipSalarySystem />} />
          <Route path='/Expenses' element={< Expenses />} />
          <Route path='/TasksTracking' element={< TasksTracking />} />
          <Route path='/Subscriptions' element={< Subscriptions />} />
          <Route path='/LeadsProposals' element={< LeadsProposals />} />
          <Route path='/Estimates' element={< Estimates />} />
          <Route path='/Integrations' element={< Integrations />} />
          <Route path='/TemplatesDocuments' element={< TemplatesDocuments />} />
          <Route path='/ChatCallSupport' element={< ChatCallSupport />} />
          <Route path='/EmailsSMS' element={< EmailsSMS />} />
          <Route path='/Contacts' element={< Contacts />} />
          <Route path='/LoyaltyProgram' element={< LoyaltyProgram />} />
          <Route path='/Contracts' element={< Contracts />} />
          <Route path='/StaffDetailsRecruitment' element={< StaffDetailsRecruitment />} />
          <Route path='/StaffDetailsRecruitment/view/:id' element={< StaffProfiles3 />} />
          <Route path='/AccountingProjects' element={< AccountingProjects />} />
          <Route path='/CustomerSupportTickets' element={< CustomerSupportTickets />} />
          <Route path='/PurchaseLogistics' element={< PurchaseLogistics />} />
          <Route path='/Analytics' element={< Analytics />} />
          <Route path='/CustomerDataAnalytics' element={< CustomerDataAnalytics />} />
          <Route path='/Inventory' element={< Inventory />} />
          <Route path='/Suppliers' element={< Suppliers />} />
          <Route path='/Assets' element={< Assets />} />
          <Route path='/Purchases' element={< Purchases />} />
          <Route path='/Calendar' element={< Calendar />} />
          <Route path='/Knowledge' element={< Knowledge />} />

          <Route path='/career' element={<Recuimentform />} />
          <Route path='/career/:id' element={<Recuimentform />} />
        
          <Route path='/Staffacountssetting' element={<Staffacountssetting/>} />




        </Routes>



      </BrowserRouter>
    </div>
  );
}

export default App;
