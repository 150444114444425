import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import "./Css/InvoicesPayments.css"
import { message } from 'antd';


function InvoicesPayments() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")
  const [popup, setpopup] = useState(false)

  const [invoice, setinvoice] = useState([])
  const [device, setdevice] = useState([])
  const [staffprofiles, setstaffprofiles] = useState([])

  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getinvoice", requestOptions)
      .then(response => response.json())
      .then(result => setinvoice(result))
      .catch(error => console.log('error', error));





    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getdevices", requestOptions)
      .then(response => response.json())
      .then(result => setdevice(result))
      .catch(error => console.log('error', error));



    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getCustomerprofiles", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));












  }, [])

  const [popup2sub, setpopup2sub] = useState("1")
  const [DevicePrice, setDevicePrice] = useState("")

  const [PackagePrice, setPackagePrice] = useState("")

  const [SimPrice, setSimPrice] = useState("")

  const [Taxes, setTaxes] = useState("")

  const [Other, setOther] = useState("")




  const [Shipto, setShipto] = useState("")
  const [Currency, setCurrency] = useState("")
  const [InvoiceBillingDate, setInvoiceBillingDate] = useState("")
  const [InvoiceDuedate, setInvoiceDuedate] = useState("")
  const [Recurring, setRecurring] = useState("")
  const [DiscountType, setDiscountType] = useState("")
  const [TotalCycles, setTotalCycles] = useState("")
  const [Depositpaid, setDepositpaid] = useState("")
  const [Donatetoacharity, setDonatetoacharity] = useState("")
  const [TotalToPay, setTotalToPay] = useState("")

  const [customer, setcustomer] = useState([])




  const [btn, setbtn] = useState(true)


  const submitdata2 = async (e) => {

    await setbtn(false)
    e.preventDefault()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();

    urlencoded.append("CustomerID", customer._id);

    urlencoded.append("DevicePrice", DevicePrice);
    urlencoded.append("PackagePrice", PackagePrice);
    urlencoded.append("SimPrice", SimPrice);
    urlencoded.append("Taxes", Taxes);
    urlencoded.append("Other", Other);


    urlencoded.append("Shipto", Shipto);
    urlencoded.append("Currency", Currency);
    urlencoded.append("InvoiceBillingDate", InvoiceBillingDate);
    urlencoded.append("InvoiceDuedate", InvoiceDuedate);
    urlencoded.append("Recurring", Recurring);
    urlencoded.append("DiscountType", DiscountType);
    urlencoded.append("TotalCycles", TotalCycles);
    urlencoded.append("Depositpaid", Depositpaid);
    urlencoded.append("Donatetoacharity", Donatetoacharity);
    urlencoded.append("TotalToPay", TotalToPay);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addinvoice", requestOptions)
      .then(response => response.json())
      .then(result => {

        // setinvoice(result)



        setpopup(false)
        setpopup(false)
        setpopup2sub("1")
        setbtn(true)
      })
      .catch(error => console.log('error', error));




    var requestOptions2 = {
      method: 'GET',

      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/getinvoice", requestOptions2)
      .then(response => response.json())
      .then(result => setinvoice(result))
      .catch(error => console.log('error', error));




  }










  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>


            <div className='header'>
              <h1 className='pppheading'> Invoices & Payments</h1>


            </div>
            <div className='Tableheader'><h3>******* </h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>


            <div style={{ marginTop: '50px' }}></div>

            {invoice.map((value) => {

              var CustomerName
              var Email
              var ID
              var Address


              var deviceType
              var manufacturer
              var IMEI
              var SimNumber

              staffprofiles.map((value2) => {

                if (value2._id === value.CustomerID) {

                  CustomerName = value2.FullName
                  Email = value2.email
                  ID = value2.IDNumber
                  Address = value2.Address

                }

              })

              device.map((value3) => {

                if (value3._id === value.DeviceID) {

                  deviceType = value3.deviceType
                  manufacturer = value3.manufacturer
                  IMEI = value3.IMEI
                  SimNumber = value3.SimNumber

                }

              })


              return (<>
                <div>
                  <div className='invoicebox'>
                    <div className='invoiceinside'>
                      <div>
                        <h3>{CustomerName}</h3>
                        <p>{Email}</p>
                        <p>{ID}</p>
                        <p>{Address}</p>
                        <p>{Email}</p>


                        <p>   Ship to : {value.Shipto} </p>
                        <p>    Currency : {value.Currency} </p>
                        <p>   Invoice Billing Date : {value.InvoiceBillingDate} </p>
                        <p>   Invoice Due date : {value.InvoiceDuedate} </p>
                      </div>


                      <div>

                        <h3>{deviceType}</h3>
                        <p>{manufacturer}</p>
                        <p>{IMEI}</p>
                        <p>{SimNumber}</p>

                      </div>

                      <div>

                        <h3>Prices </h3>
                        <p>Device Price : {value.DevicePrice} </p>
                        <p>Package Price : {value.PackagePrice} </p>
                        <p>Sim Price : {value.SimPrice} </p>
                        <p>Taxes : {value.Taxes} </p>
                        <p>Other : {value.Other} </p>

                        <p>   Recurring  : {value.Recurring}    </p>
                        <p>   DiscountType  : {value.DiscountType}  %  </p>
                        <p>   TotalCycles  : {value.TotalCycles}    </p>
                        <p>  Depositpaid  : {value.Depositpaid}    </p>
                        <p>   Donatetoacharity  : {value.Donatetoacharity}    </p>
                        <p>   TotalToPay  : {value.TotalToPay}    </p>

                      </div>
                    </div>
                    <div>
                      <h3>{value.Package}</h3>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <button className='formalbtncncl'

                        onClick={() => {



                          var myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                          var urlencoded = new URLSearchParams();
                          urlencoded.append("_id", value._id);


                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                          };

                          fetch("https://backend.datawarehousegh.com:1337/dtlinvoice", requestOptions)
                            .then(response => response.json())
                            .then(result => {
                              setinvoice(result)



                            })
                            .catch(error => console.log('error', error));





                        }}






                      >Delete</button>
                      <button className='formalbtn'>Download</button>

                    </div>
                  </div>

                </div>
              </>)
            })}
          </div>


          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => {
                setpopup2sub("1")
                setpopup(false)
              }}></div>

              {popup2sub === "1" && <>
                <div className='popupcontant'>
                  <h2>Select The Customer to Assign This device </h2>
                  <div className='customerassignbox'>


                    {staffprofiles.map((value) => <>
                      <div className='customerassigncard' onClick={() => {
                        setpopup2sub("4")
                        setcustomer(value)
                      }}>
                        <h3>Name : {value.FullName}</h3>
                        <h3>Email : {value.email}</h3>
                        <h3>ID Number : {value.IDNumber}</h3>
                      </div>
                    </>)}
                  </div>

                  <button className='formalbtncncl' onClick={() => {
                    setpopup2sub("1")
                    setpopup(false)
                  }}>Cencel</button>
                </div>
              </>}





              {/* {popup2sub === "3" && <>
                <div className='popupcontant'>
                  <h2>Enter Sim the Details Below</h2>
                  <form onSubmit={
                    (e) => {
                      e.preventDefault()
                      setpopup2sub("4")

                    }
                  } className='newenteryform'>




                    <div>  <label>Sim ID :</label><input value={SimID} onChange={(e) => setSimID(e.target.value)} /></div>
                    <div>  <label>Sim Number :</label><input value={SimNumber} onChange={(e) => setSimNumber(e.target.value)} /></div>
                    <div>  <label>Sim Status :</label><input value={SimStatus} onChange={(e) => setSimStatus(e.target.value)} /></div>






                    {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                    </> : <>
                      <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                        e.preventDefault()
                        message.error("Please Wait")
                      }}>Please Wait</button>
                    </>}
                    <button className='formalbtncncl' onClick={() => {
                      setpopup2sub("1")
                      setpopup2(false)
                    }}>Cencel</button>

                  </form>
                </div>
              </>} */}








              {popup2sub === "4" && <>
                <div className='popupcontant'>
                  <h2>Enter The Prices</h2>
                  <form onSubmit={submitdata2} className='newenteryform'>
                    <div className='det' style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>



                      <h4 style={{ marginTop: '10px' }}>Customer Details</h4>
                      <p style={{ margin: '0' }}>Customer Name: {customer.FullName}</p>
                      <p style={{ margin: '0' }}>Customer Email : {customer.email}</p>
                      <p style={{ margin: '0' }}>Customer ID :{customer.IDNumber}</p>




                    </div>
                    <div >   <label>Device Price :</label><input value={DevicePrice} onChange={(e) => setDevicePrice(e.target.value)} /></div>
                    <div>  <label>Package Price :</label><input value={PackagePrice} onChange={(e) => setPackagePrice(e.target.value)} /></div>
                    <div>  <label>Sim Price :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>Taxes :</label><input value={Taxes} onChange={(e) => setTaxes(e.target.value)} /></div>
                    <div>  <label>Other :</label><input value={Other} onChange={(e) => setOther(e.target.value)} /></div>


                    {/* <div>  <label>Shipto :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>Currency :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>SalesAgent :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>InvoiceBillingDate :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>InvoiceDuedate :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>Recurring :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>DiscountType :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>TotalCycles :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>Depositpaid :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>Levies :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>Donatetoacharity :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>TotalToPay :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div> */}



                    <div>  <label>Ship to :</label><input value={Shipto} onChange={(e) => setShipto(e.target.value)} /></div>
                    <div>  <label>Currency :</label><input value={Currency} onChange={(e) => setCurrency(e.target.value)} /></div>
                    <div>  <label>Sales Agent :</label><input value={loginemail} disabled /></div>
                    <div>  <label>Invoice Billing Date :</label><input type='date' value={InvoiceBillingDate} onChange={(e) => setInvoiceBillingDate(e.target.value)} /></div>
                    <div>  <label>Invoice Due date :</label><input type='date' value={InvoiceDuedate} onChange={(e) => setInvoiceDuedate(e.target.value)} /></div>
                    <div>  <label>Recurring :</label>
                      <select id="id" name="id" onChange={(e) => setRecurring(e.target.value)}>
                        <option disabled selected> select option</option>
                        <option value="1 month ">1 month </option>
                        <option value="3 months ">3 months </option>
                        <option value="6 months ">6 months </option>
                        <option value="12 months ">12 months </option>

                      </select>

                    </div>
                    <div>  <label>Discount Type :</label><input type='number' placeholder='Just type the number' value={DiscountType} onChange={(e) => setDiscountType(e.target.value)} /></div>
                    <div>  <label>Total Cycles :</label><input value={TotalCycles} onChange={(e) => setTotalCycles(e.target.value)} /></div>
                    <div>  <label>Deposit paid :</label><input value={Depositpaid} onChange={(e) => setDepositpaid(e.target.value)} /></div>
                    {/* <div>  <label>Levies :</label><input type='radio' value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div> */}
                    <div>  <label>Donate to a charity :</label><input value={Donatetoacharity} onChange={(e) => setDonatetoacharity(e.target.value)} /></div>
                    <div>  <label>Total To Pay :</label><input value={TotalToPay} onChange={(e) => setTotalToPay(e.target.value)} /></div>



                    {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                    </> : <>
                      <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                        e.preventDefault()
                        message.error("Please Wait")
                      }}>Please Wait</button>
                    </>}
                    <button className='formalbtncncl' onClick={() => {
                      setpopup2sub("1")
                      setpopup(false)
                    }}>Cencel</button>

                  </form>
                </div>
              </>}


            </div>
          </>}


        </div>
      </>}  </div>
  )
}

export default InvoicesPayments