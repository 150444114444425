import React from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import "../css/dashboard.css"
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';



function Dashboard() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")

  const Navigate = useNavigate()


  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>



            <h1>Finance</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/FinancialDetails")} style={{ backgroundColor: '#3598DC' }}>

                <h2>Financial Details</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/InvoicesPayments")} style={{ backgroundColor: '#E12330' }}>

                <h2>Invoices</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#26A1AB' }}>

                <h2>Payments</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Creditnotes")} style={{ backgroundColor: '#703688' }}>

                <h2>Credit notes</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Commissions")} style={{ backgroundColor: '#C09900' }}>

                <h2>Commissions</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Expenses")} style={{ backgroundColor: '#CBD5DA' }}>

                <h2>Expenses</h2>
              </div>


            </div>



            <h1>Subcription</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/Subscriptions")} style={{ backgroundColor: '#BFCAD1' }}>

                <h2>Mifi Subscriptions</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#3782C3' }}>

                <h2>Router Subscriptions</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#D91E18' }}>

                <h2>Dongle Subscriptions</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#1A252F' }}>

                <h2>TV Subscriptions</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#158F76' }}>

                <h2>DeskPhone Subscriptions</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#F3C200' }}>

                <h2>Andriod TV Box Subscriptions</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#29B4B6' }}>

                <h2>POE Subscriptions</h2>
              </div>


            </div>


            <h1>Human Resources</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/StaffProfiles")} style={{ backgroundColor: '#208B8C' }}>

                <h2>Staff Details</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/PayslipSalarySystem")} style={{ backgroundColor: '#CF1C28' }}>

                <h2>Payslip</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/PayslipSalarySystem")} style={{ backgroundColor: '#24B699' }}>

                <h2>Salary System</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/StaffDetailsRecruitment")} style={{ backgroundColor: '#AB1813' }}>

                <h2>Recruitment</h2>
              </div>



            </div>


            <h1>Operations</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/AccountingProjects")} style={{ backgroundColor: '#E87E04' }}>

                <h2>Accounting</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#8E44AD' }}>

                <h2>Projects</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/TasksTracking")} style={{ backgroundColor: '#6D5B8E' }}>

                <h2>Tasks & Tracking</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Analytics")} style={{ backgroundColor: '#6D5B8E' }}>

                <h2>Analytics</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/CustomerDataAnalytics")} style={{ backgroundColor: '#A1B1BC' }}>

                <h2>Customer Data</h2>
              </div>



            </div>


            <h1>Logistics</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/Inventory")} style={{ backgroundColor: '#1A252F' }}>

                <h2>Inventory</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/PurchaseLogistics")} style={{ backgroundColor: '#1BBC9B' }}>

                <h2>Purchase & Logistics</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Suppliers")} style={{ backgroundColor: '#F7CA18' }}>

                <h2>Supplies & Approved Vendors</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Assets")} style={{ backgroundColor: '#5C9BD1' }}>

                <h2>Assets Management</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Purchases")} style={{ backgroundColor: '#36918B' }}>

                <h2>Purchases</h2>
              </div>



            </div>


            <h1>Utilities</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/Calendar")} style={{ backgroundColor: '#F36A5A' }}>

                <h2>Calendar</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/CustomerSupportTickets")} style={{ backgroundColor: '#F3C200' }}>

                <h2>Customer Support</h2>
              </div>



              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#B66303' }}>

                <h2>Tickets</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Knowledge")} style={{ backgroundColor: '#AB1813' }}>

                <h2>Knowledge</h2>
              </div>




            </div>


            <h1>Account Management</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/Staffacountssetting")} style={{ backgroundColor: '#703688' }}>

                <h2>Staff Accounts</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/CustomerAccounts")} style={{ backgroundColor: '#8775A7' }}>

                <h2>Customer Accounts</h2>
              </div>







            </div>



            <h1>Sales and Marketing</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/Integrations")} style={{ backgroundColor: '#24B699' }}>

                <h2>Integrations</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/TemplatesDocuments")} style={{ backgroundColor: '#CF1C28' }}>

                <h2>Templates & Documents</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/LeadsProposals")} style={{ backgroundColor: '#703688' }}>

                <h2>Leads</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#2C3E50' }}>

                <h2>Proposals</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Estimates")} style={{ backgroundColor: '#8775A7' }}>

                <h2>Estimates</h2>
              </div>



            </div>


            <h1>Communication</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/ChatCallSupport")} style={{ backgroundColor: '#3B6D97' }}>

                <h2>Chat & Call Support</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/EmailsSMS")} style={{ backgroundColor: '#A1B1BC' }}>

                <h2>Emails & SMS</h2>
              </div>






            </div>



            <h1>Customer and company</h1>
            <div className='dashbordboxcantainer'>
              <div className='dashboardbox' onClick={() => Navigate("/Contacts")} style={{ backgroundColor: '#44B6AE' }}>

                <h2>Contacts</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/LoyaltyProgram")} style={{ backgroundColor: '#1E9765' }}>

                <h2>Loyalty Program</h2>
              </div>


              <div className='dashboardbox' onClick={() => Navigate("/Contracts")} style={{ backgroundColor: '#3B6D97' }}>

                <h2>Contracts</h2>
              </div>







            </div>



          </div>
        </div>
      </>}  </div>
  )
}

export default Dashboard