import React, { useState, useEffect } from 'react'
import "./Css/staffprofiles.css"
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import { TiEdit } from "react-icons/ti";
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie';
import { FaFacebookSquare, FaInstagram, FaYoutube,FaTiktok  } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { AiFillTikTok } from "react-icons/ai";

function StaffProfiles3() {

  const idd = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")


  const Navigate = useNavigate()




  const [FullName2, setFullName2] = useState("")
  const [DateOfBirth2, setDateOfBirth2] = useState("")
  const [Address2, setAddress2] = useState("")
  const [ContactDetails2, setContactDetails2] = useState("")
  const [NextOfKinNameandContactNumber2, setNextOfKinNameandContactNumber2] = useState("")
  const [SocailSecurityNumber2, setSocailSecurityNumber2] = useState("")
  const [IDNumber2, setIDNumber2] = useState("")
  const [EmergancyContactNumberAndName2, setEmergancyContactNumberAndName2] = useState("")
  const [Title2, setTitle2] = useState("")
  const [Department2, setDepartment2] = useState("")
  const [Supervisor2, setSupervisor2] = useState("")
  const [WorkLocation2, setWorkLocation2] = useState("")
  const [StartingDate2, setStartingDate2] = useState("")
  const [Salary2, setSalary2] = useState("")
  const [FacebookLink2, setFacebookLink2] = useState("")
  const [TwitterLink2, setTwitterLink2] = useState("")
  const [TiktiokLink2, setTiktiokLink2] = useState("")
  const [InstagramLink2, setInstagramLink2] = useState("")
  const [youtubeLink2, setyoutubeLink2] = useState("")
  const [PerformanceAppraisalRelatedNotes2, setPerformanceAppraisalRelatedNotes2] = useState("")
  const [PerformanceAppraisalRelatedNotes, setPerformanceAppraisalRelatedNotes] = useState("")

  const [OfficalName2, setOfficalName2] = useState("")
  const [OfficalPosition2, setOfficalPosition2] = useState("")
  const [AppraisalDate2, setAppraisalDate2] = useState("")
  const [status, setstatus] = useState("")
  const [profilepic, setprofilepic] = useState()
  const [cv, setcv] = useState()
  const [email, setemail] = useState("")

  const [password, setpassword] = useState("")
  const [btn, setbtn] = useState(true)

  const { id } = useParams();






  const reject = async () => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/dtlstaffprofilescareer", requestOptions)
      .then(response => response.json())
      .then(result => {
        message.success("rejected")
        Navigate("/StaffDetailsRecruitment")



      })
      .catch(error => console.log('error', error));


  }


  const Accept = async () => {


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/acceptinterviesstaffprofilescareer", requestOptions)
      .then(response => response.json())
      .then(result => {
        message.success("Accepted For Interview")
        Navigate("/StaffDetailsRecruitment")



      })
      .catch(error => console.log('error', error));


  }







  const submitdata = async (e) => {

    await setbtn(false)
    e.preventDefault()





    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("FullName", FullName2);
    urlencoded.append("DateOfBirth", DateOfBirth2);
    urlencoded.append("Address", Address2);
    urlencoded.append("ContactDetails", ContactDetails2);
    urlencoded.append("NextOfKinNameandContactNumber", NextOfKinNameandContactNumber2);
    urlencoded.append("SocailSecurityNumber", SocailSecurityNumber2);
    urlencoded.append("IDNumber", IDNumber2);
    urlencoded.append("EmergancyContactNumberAndName", EmergancyContactNumberAndName2);
    urlencoded.append("Title", Title2);
    urlencoded.append("Department", Department2);
    urlencoded.append("Supervisor", Supervisor2);
    urlencoded.append("WorkLocation", WorkLocation2);
    urlencoded.append("StartingDate", StartingDate2);
    urlencoded.append("Salary", Salary2);
    urlencoded.append("FacebookLink", FacebookLink2);
    urlencoded.append("TwitterLink", TwitterLink2);
    urlencoded.append("TiktiokLink", TiktiokLink2);
    urlencoded.append("InstagramLink", InstagramLink2);
    urlencoded.append("youtubeLink", youtubeLink2);
    urlencoded.append("PerformanceAppraisalRelatedNotes", PerformanceAppraisalRelatedNotes2);
    urlencoded.append("OfficalName", OfficalName2);
    urlencoded.append("OfficalPosition", OfficalPosition2);
    urlencoded.append("AppraisalDate", AppraisalDate2);

    urlencoded.append("email", email);
    urlencoded.append("cv", cv);
    urlencoded.append("profilepic", profilepic);
    urlencoded.append("password", password);




    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addstaff", requestOptions)
      .then(response => response.json())
      .then(result => {





        setbtn(true)


      })
      .catch(error => console.log('error', error));











    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded2 = new URLSearchParams();
    urlencoded2.append("_id", id);


    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded2,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/dtlstaffprofilescareer", requestOptions2)
      .then(response => response.json())
      .then(result => {
        message.success("Added in Staff")
        Navigate("/StaffDetailsRecruitment")



      })
      .catch(error => console.log('error', error));









  }






  const [value, setvalue] = useState({})






  useEffect(() => {



    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("_id", id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/viewstaffprofilecareer", requestOptions)
      .then(response => response.json())
      .then(result => {


        setFullName2(result.FullName)
        setDateOfBirth2(result.DateOfBirth)
        setAddress2(result.Address)
        setContactDetails2(result.ContactDetails)
        setNextOfKinNameandContactNumber2(result.NextOfKinNameandContactNumber)
        setSocailSecurityNumber2(result.SocailSecurityNumber)
        setIDNumber2(result.IDNumber)
        setEmergancyContactNumberAndName2(result.EmergancyContactNumberAndName)

        setFacebookLink2(result.FacebookLink)
        setTwitterLink2(result.TwitterLink)
        setTiktiokLink2(result.TiktiokLink)
        setInstagramLink2(result.InstagramLink)
        setyoutubeLink2(result.youtubeLink)
        setPerformanceAppraisalRelatedNotes(result.PerformanceAppraisalRelatedNotes)
        setstatus(result.status)
        setemail(result.email)
        setcv(result.cv)
        setprofilepic(result.profilepic)
        setvalue(result)

      }


      )
      .catch(error => console.log('error', error));



  }, [])


  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>


        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>



            <div className='header'>
              <h1 className='pppheading'> Staff Profiles</h1>


            </div>

            {status === "Newform" && <><div className='Tableheader' style={{ justifyContent: 'flex-end', gap: "20px" }}><h3></h3>


              <button onClick={Accept} className='formalbtn' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>Accept for an interview.</button>
              <button onClick={reject} className='formalbtn' style={{ display: 'flex', alignItems: 'center', gap: '10px', backgroundColor: 'white', color: 'red' }}>Reject</button>


            </div>
            </>}




            <div style={{ display: 'flex', width: '100%', height: "fit-contant", gap: '10px' }}>
              <div className='profilepix' style={{ width: '40%' }} >
                <img alt='' src={profilepic} />
              </div>
              <div style={{ width: '60%' }}>

                <div className='infodivvv'> <h3>Full Name :</h3> <h3> &nbsp;{FullName2}</h3> </div>
                <div className='infodivvv'> <h3>Date Of Birth :</h3> <h3> &nbsp;{DateOfBirth2}</h3> </div>
                <div className='infodivvv'> <h3>Address :</h3> <h3> &nbsp;{Address2}</h3> </div>
                <div className='infodivvv'> <h3>Contact Details :</h3> <h3> &nbsp;{ContactDetails2}</h3> </div>
                <div className='infodivvv'> <h3>Next Of Kin Name and Contact Number :</h3> <h3> &nbsp;{NextOfKinNameandContactNumber2}</h3> </div>
                <div className='infodivvv'> <h3>Socail Security Number :</h3> <h3> &nbsp;{SocailSecurityNumber2}</h3> </div>
                <div className='infodivvv'> <h3>ID Number :</h3> <h3> &nbsp;{IDNumber2}</h3> </div>
                <div className='infodivvv'> <h3>Emergancy Contact Number And Name :</h3> <h3> &nbsp;{EmergancyContactNumberAndName2}</h3> </div>
                <div className='infodivvv'> <h3>email :</h3> <h3> &nbsp;{email}</h3> </div>


              </div>
            </div>



            <h2 style={{ marginTop: '50px' }}>Applied For</h2>
            <div className='joblist'>
              <h3>job title  :   {value.jobtitle}</h3>
              <h4>Location   :   {value.Location}</h4>
              <h4>full or part :  {value.Condition}</h4>
              <h4>Shift :  {value.shift}</h4>
              <div> <h4>Discription</h4>
                <p> {value.Discription}</p>
              </div>
             

            </div>

            <h2 style={{ marginTop: '50px' }}>Socail Media Links</h2>
            <div className='socailmedialinks'>
              <a href={"https://"+FacebookLink2}>

                <FaFacebookSquare style={{ width: '80px', height: '80px', color: "#1877F2" }} width={"100px"} height={"100px"} />
              </a>
              <a href={"https://"+TwitterLink2}>

                <FaXTwitter style={{ width: '80px', height: '80px', color: "#1DA1F2 " }} width={"100px"} height={"100px"} />
              </a>

              <a href={"https://"+TiktiokLink2}>

                <FaTiktok  style={{ width: '80px', height: '80px', color: "#ff0050 " }} width={"100px"} height={"100px"} />
              </a>


              <a href={"https://"+InstagramLink2}>

                <FaInstagram style={{ width: '80px', height: '80px', color: "#FCAF45 " }} width={"100px"} height={"100px"} />
              </a>

              <a href={"https://"+youtubeLink2}>

                <FaYoutube style={{ width: '80px', height: '80px', color: "#FF0000 " }} width={"100px"} height={"100px"} />
              </a>


            </div>


            <h2 style={{ marginTop: '50px' }}>Details</h2>

            <div className='infodivvv'> <h3> &nbsp;{PerformanceAppraisalRelatedNotes}</h3> </div>





            <button className='formalbtn' onClick={() => {
              const link = document.createElement('a');
              link.href = cv;
              link.setAttribute('download', ''); // This will prompt the browser to download the file
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);


            }} >Download CV</button>

            {status === "interview" && <>
              <form onSubmit={submitdata} style={{ marginTop: '50px' }} className='newenteryform'>


                <h2>Job Imformation</h2>
                <div><label>Title :</label><input required value={Title2} onChange={(e) => setTitle2(e.target.value)} /></div>
                <div><label>Department :</label><input required value={Department2} onChange={(e) => setDepartment2(e.target.value)} /></div>
                <div><label>Supervisor :</label><input required value={Supervisor2} onChange={(e) => setSupervisor2(e.target.value)} /></div>
                <div><label>Work Location :</label><input required value={WorkLocation2} onChange={(e) => setWorkLocation2(e.target.value)} /></div>
                <div><label>Starting Date :</label><input required value={StartingDate2} onChange={(e) => setStartingDate2(e.target.value)} /></div>
                <div><label>Salary :</label><input required value={Salary2} onChange={(e) => setSalary2(e.target.value)} /></div>


                <h2>Performance Appraisal Related Notes</h2>
                <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} value={PerformanceAppraisalRelatedNotes2} onChange={(e) => setPerformanceAppraisalRelatedNotes2(e.target.value)} /></div>
                <div><label>Offical's Name :</label><input required value={OfficalName2} onChange={(e) => setOfficalName2(e.target.value)} /></div>
                <div><label>Offical's Position :</label><input required value={OfficalPosition2} onChange={(e) => setOfficalPosition2(e.target.value)} /></div>
                <div><label>Appraisal's Date :</label><input required value={AppraisalDate2} onChange={(e) => setAppraisalDate2(e.target.value)} /></div>


                <h2>login credentials</h2>
                <div><label>Email :</label><input required value={email} onChange={(e) => setemail(e.target.value)} /></div>
                <div><label>Passpord :</label><input type='password' required value={password} onChange={(e) => setpassword(e.target.value)} /></div>


                {btn ? <>  <button className='formalbtn' type='submit'>Accept</button>
                </> : <>
                  <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                    e.preventDefault()
                    message.error("Please Wait")
                  }}>Please Wait</button>
                </>}
                <button className='formalbtncncl' onClick={reject}>Reject</button>

              </form>
            </>}






          </div>



        </div>
      </>}  </div>
  )
}

export default StaffProfiles3