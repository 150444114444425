import React, { useState, useEffect } from 'react'
import "./Css/staffprofiles.css"
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import { AiFillDelete } from "react-icons/ai"
import { Navigate, useNavigate } from 'react-router-dom'
import Password from 'antd/es/input/Password'
import Cookies from 'js-cookie';
import { v4 } from 'uuid';
import { imageDb } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";


function Contracts() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  // const token = "dskgfsdgfkgsdfkjg35464154845674987dsf@53"
  const loginemail = Cookies.get("email")


  const Navigate = useNavigate()
  const [viewdetails, setviewdetails] = useState(false)
  const [popup, setpopup] = useState(false)

  const [ContractID, setContractID] = useState("")
  const [Client, setClient] = useState("")

  const [StartDate, setStartDate] = useState("")

  const [EndDate, setEndDate] = useState("")

  const [Services, setServices] = useState("")

  const [TermsandConditions, setTermsandConditions] = useState("")

  const [ContractValue, setContractValue] = useState("")

  const [PaymentSchedule, setPaymentSchedule] = useState("")

  const [ContractStatus, setContractStatus] = useState("")

  const [Attachments, setAttachments] = useState("")



  const [btn, setbtn] = useState(true)

  const [staffprofiles, setstaffprofiles] = useState([])





  const submitdata = async (e) => {

    await setbtn(false)
    e.preventDefault()



    const Carimageid = v4();
    const ex = Attachments.name.split('.')[1]
    const imgRef = ref(imageDb, `files/${Carimageid}.${ex}`)
    await uploadBytes(imgRef, Attachments)
    const AttachmentsUrl = await getDownloadURL(imgRef);


    console.log(AttachmentsUrl)




    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("ContractID", ContractID);
    urlencoded.append("Client", Client);
    urlencoded.append("StartDate", StartDate);
    urlencoded.append("EndDate", EndDate);
    urlencoded.append("Services", Services);
    urlencoded.append("TermsandConditions", TermsandConditions);
    urlencoded.append("ContractValue", ContractValue);
    urlencoded.append("PaymentSchedule", PaymentSchedule);
    urlencoded.append("ContractStatus", ContractStatus);
    urlencoded.append("Attachments", AttachmentsUrl);



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    await fetch("https://backend.datawarehousegh.com:1337/addcontract", requestOptions)
      .then(response => response.json())
      .then(result => {


        if (result === "user found") {
          message.error("Email is already in Database")
          setbtn(true)
        }
        else {

          setstaffprofiles(result)
          setpopup(false);

          setbtn(true);



        }

      })
      .catch(error => console.log('error', error));



  }

  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getcontract", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));















  }, [])



  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>



            <div className='header'>
              <h1 className='pppheading'> Contracts</h1>


            </div>
            {viewdetails === false && <>

              <div className='Tableheader'><h3></h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>

              {staffprofiles.length !== 0 && <>
                <table className='tablep'>
                  <thead className='tablephead'>
                    <tr>
                      <th>S NO.</th>
                      <th> Contract ID</th>
                      <th> Client</th>

                      <th> Start Date</th>
                      <th> End Date</th>
                      {/* <th>ID Type</th> */}
                      <th> Services/Products Covered</th>
                      <th> Terms and Conditions</th>
                      <th> Contract Value</th>
                      <th> Payment Schedule</th>
                      <th> Contract Status</th>
                      <th> Attachments</th>


                      <th></th>
                    </tr>
                  </thead>
                  <tbody>

                    {staffprofiles.map((value, index) => {

                      return (

                        <tr>

                          <td>{index + 1}</td>

                          <td>{value.ContractID}</td>
                          <td>{value.Client}</td>

                          {/* <td>{value.}</td> */}
                          <td>{value.StartDate}</td>
                          <td>{value.EndDate}</td>
                          <td>{value.Services}</td>
                          <td>{value.TermsandConditions}</td>
                          <td>{value.ContractValue}</td>
                          <td>{value.PaymentSchedule}</td>
                          <td>{value.ContractStatus}</td>
                          <td>

                            <button className='formalbtn' onClick={() => {
                              const link = document.createElement('a');
                              link.href = value.Attachments;
                              link.setAttribute('download', ''); // This will prompt the browser to download the file
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);


                            }} >Download </button>

                          </td>


                          <td>
                            <AiFillDelete className='hovar' onClick={async () => {

                              var myHeaders = new Headers();
                              myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                              var urlencoded = new URLSearchParams();
                              urlencoded.append("_id", value._id);


                              var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: urlencoded,
                                redirect: 'follow'
                              };

                              await fetch("https://backend.datawarehousegh.com:1337/dtlcontract", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                  setstaffprofiles(result)



                                })
                                .catch(error => console.log('error', error));


                            }} style={{ color: "red", width: '25px', height: '25px' }} />
                          </td>

                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </>}
            </>}





          </div>


          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>
                <form onSubmit={submitdata} className='newenteryform'>

                  <div>  <label> Contract ID :</label><input required value={ContractID} onChange={(e) => setContractID(e.target.value)} /></div>
                  <div><label> Client :</label><input required value={Client} onChange={(e) => setClient(e.target.value)} /></div>
                  <div><label> Start Date :</label><input required value={StartDate} onChange={(e) => setStartDate(e.target.value)} /></div>
                  <div><label>End Date :</label><input required value={EndDate} onChange={(e) => setEndDate(e.target.value)} /></div>
                  <div><label> Services/Products Covered :</label><input required value={Services} onChange={(e) => setServices(e.target.value)} /></div>
                  <div><label> Terms and Conditions :</label><input required value={TermsandConditions} onChange={(e) => setTermsandConditions(e.target.value)} /></div>
                  <div><label> Contract Value :</label><input required value={ContractValue} onChange={(e) => setContractValue(e.target.value)} /></div>
                  <div><label> Payment Schedule :</label><input required value={PaymentSchedule} onChange={(e) => setPaymentSchedule(e.target.value)} /></div>
                  <div><label> Contract Status :</label><input required value={ContractStatus} onChange={(e) => setContractStatus(e.target.value)} /></div>
                  <div><label>Attachments :</label><input onChange={(e) => setAttachments(e.target.files[0])} required type='file' /></div>






                  {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                  </> : <>
                    <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                      e.preventDefault()
                      message.error("Please Wait")
                    }}>Please Wait</button>
                  </>}
                  <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

                </form>
              </div>
            </div>
          </>}
        </div>
      </>}  </div>
  )
}

export default Contracts