import React from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';


function Estimates() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")

  
  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>


    <Navbar />
    <div className='mainbody'>
      <Sidemanu />
      <div className='dashbordbody'>Estimates</div>
      </div>
    </>}  </div>
  )
}

export default Estimates