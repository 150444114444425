import React, { useState, useEffect } from 'react'
import { AiFillDelete } from "react-icons/ai"
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import { message } from 'antd'
import Cookies from 'js-cookie';
import "./Css/inventry.css"


function Inventory() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")


  const [popup, setpopup] = useState(false)
  const [popup2, setpopup2] = useState(false)
  const [popup2sub, setpopup2sub] = useState("1")
  const [btn, setbtn] = useState(true)
  const [DeviceID, setDeviceID] = useState("")

  const [productselected, setproductselected] = useState("");



  const [deviceType, setdeviceType] = useState("")
  const [manufacturer, setmanufacturer] = useState("")
  const [batchNumber, setbatchNumber] = useState("")
  const [MacAddress, setMacAddress] = useState("")
  const [serialNumber, setserialNumber] = useState("")
  const [IMEI, setIMEI] = useState("")
  const [serverIP, setserverIP] = useState("")
  const [SimID, setSimID] = useState("")
  const [SimNumber, setSimNumber] = useState("")
  const [SimStatus, setSimStatus] = useState("")

  const [DevicePrice, setDevicePrice] = useState("")

  const [PackagePrice, setPackagePrice] = useState("")

  const [SimPrice, setSimPrice] = useState("")

  const [Taxes, setTaxes] = useState("")

  const [Other, setOther] = useState("")



  const [device, setdevice] = useState([])
  const [customer, setcustomer] = useState([])
  const [selectedCell, setSelectedCell] = useState(null);



  const [staffprofiles, setstaffprofiles] = useState([])


  const submitdata = async (e) => {

    await setbtn(false)
    e.preventDefault()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("deviceType", deviceType);
    urlencoded.append("manufacturer", manufacturer);
    urlencoded.append("batchNumber", batchNumber);
    urlencoded.append("MacAddress", MacAddress);
    urlencoded.append("serialNumber", serialNumber);
    urlencoded.append("IMEI", IMEI);
    urlencoded.append("serverIP", serverIP);
    urlencoded.append("SimID", SimID);
    urlencoded.append("SimNumber", SimNumber);
    urlencoded.append("SimStatus", SimStatus);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/adddevice", requestOptions)
      .then(response => response.json())
      .then(result => {

        setdevice(result)


        setdeviceType("")
        setmanufacturer("")
        setbatchNumber("")
        setMacAddress("")
        setserialNumber("")
        setIMEI("")
        setserverIP("")
        setSimID("")
        setSimNumber("")
        setSimStatus("")

        setpopup(false)

        setbtn(true)
      })
      .catch(error => console.log('error', error));





  }


  const submitdata2 = async (e) => {

    await setbtn(false)
    e.preventDefault()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("DeviceID", DeviceID._id);
    urlencoded.append("CustomerID", customer._id);
    urlencoded.append("Package", selectedCell);
    urlencoded.append("DevicePrice", DevicePrice);
    urlencoded.append("PackagePrice", PackagePrice);
    urlencoded.append("SimPrice", SimPrice);
    urlencoded.append("Taxes", Taxes);
    urlencoded.append("Other", Other);

    urlencoded.append("SimID", SimID);
    urlencoded.append("SimNumber", SimNumber);
    urlencoded.append("SimStatus", SimStatus);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/Subription", requestOptions)
      .then(response => response.json())
      .then(result => {

        setdevice(result)


        setdeviceType("")
        setmanufacturer("")
        setbatchNumber("")
        setMacAddress("")
        setserialNumber("")
        setIMEI("")
        setserverIP("")
        setSimID("")
        setSimNumber("")
        setSimStatus("")

        setpopup(false)
        setpopup2(false)
        setpopup2sub("1")
        setbtn(true)
      })
      .catch(error => console.log('error', error));





  }



  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getdevices", requestOptions)
      .then(response => response.json())
      .then(result => setdevice(result))
      .catch(error => console.log('error', error));



    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getCustomerprofiles", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));








  }, [])




  const finecustomer = async (id) => {

    return "dataaaa"


  }





  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>

            <div className='header'>
              <h1 className='pppheading'> Inventory</h1>


            </div>


            <div className='Tableheader'><h3>Dongle</h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "Dongle" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>


                        {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}

                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>




            <div className='Tableheader'><h3>MIFI</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "MIFI" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>




            <div className='Tableheader'><h3>BB Router</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "BB Router" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>

            <div className='Tableheader'><h3>NBB Router</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "NBB Router" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>



            <div className='Tableheader'><h3>Desk Phone</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "Desk Phone" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>


            <div className='Tableheader'><h3>Mobile Phone</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "Mobile Phone" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>




            <div className='Tableheader'><h3>Tablet</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "Tablet" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>









            <div className='Tableheader'><h3>Leptop</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "Leptop" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>









            <div className='Tableheader'><h3>Android TV Box</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "Android TV Box" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>









            <div className='Tableheader'><h3>Smart TV</h3>
            </div>


            <table className='tablep'>
              <thead className='tablephead'>





                <tr>
                  <th>S. No</th>
                  <th>Manufacturer</th>
                  <th>Batch Number</th>
                  <th>Mac Address</th>

                  <th>Serial Number</th>
                  <th>IMEI</th>
                  {/* <th>ID Type</th> */}
                  <th>Server IP</th>
                  <th>SIM ID</th>
                  <th>SIM Number</th>
                  <th>SIM Status</th>
                  <th>Assign To</th>


                  <th></th>
                </tr>
              </thead>
              <tbody>

                {device.map((value, index) => {



                  var dataaaa = ""
                  var dataaaa2 = ""
                  staffprofiles.map((value2) => {

                    if (value.Assignto === value2._id)
                      dataaaa = value2.FullName
                    dataaaa2 = value2.email
                  })

                  // // console.log(dataaaa)



                  return (<>
                    {value.deviceType === "Smart TV" && <>
                      <tr>
                        <td>1</td>
                        <td>{value.manufacturer}</td>
                        <td>{value.batchNumber}</td>
                        <td>{value.MacAddress}</td>
                        <td>{value.serialNumber}</td>
                        <td>{value.IMEI}</td>
                        <td>{value.serverIP}</td>
                        <td>{value.SimID}</td>
                        <td>{value.SimNumber}</td>
                        <td>{value.SimStatus}</td>
                         {value.Assignto === undefined && <>
                          <td><a href='#' onClick={() => {
                            setpopup2(true)
                            setDeviceID(value)









                          }}>Assign</a></td>

                        </>}

                        {value.Assignto !== undefined && <>
                          <td>

                            {dataaaa}({dataaaa2})
                          </td>

                        </>}


                        <td>
                          <AiFillDelete className='hovar' onClick={async () => {

                            var myHeaders = new Headers();
                            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                            var urlencoded = new URLSearchParams();
                            urlencoded.append("_id", value._id);


                            var requestOptions = {
                              method: 'POST',
                              headers: myHeaders,
                              body: urlencoded,
                              redirect: 'follow'
                            };

                            await fetch("https://backend.datawarehousegh.com:1337/dtldevice", requestOptions)
                              .then(response => response.json())
                              .then(result => {
                                setdevice(result)



                              })
                              .catch(error => console.log('error', error));


                          }} style={{ color: "red", width: '25px', height: '25px' }} />
                        </td>

                      </tr>

                    </>}
                  </>)
                })}


              </tbody>
            </table>
















          </div>

          {popup === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => setpopup(false)}></div>
              <div className='popupcontant'>
                <h2>Enter the Details Below</h2>
                <form onSubmit={submitdata} className='newenteryform'>



                  <div><label>Device Type :</label>

                    <select id="id" name="id" onChange={(e) => setdeviceType(e.target.value)}>
                      <option disabled selected> select option</option>
                      <option value="Dongle">Dongle</option>
                      <option value="MIFI">MIFI</option>
                      <option value="BB Router">BB Router</option>
                      <option value="NBB Router">NBB Router</option>
                      <option value="Desk Phone">Desk Phone </option>
                      <option value="Mobile Phone">Mobile Phone</option>
                      <option value="Tablet">Tablet</option>
                      <option value="Leptop">Leptop</option>
                      <option value="Android TV Box">Android TV Box</option>
                      <option value="Smart TV">Smart TV</option>

                    </select>
                  </div>
                  <div>  <label>Manufacturer :</label><input required value={manufacturer} onChange={(e) => setmanufacturer(e.target.value)} /></div>
                  <div>  <label>Batch Number :</label><input required value={batchNumber} onChange={(e) => setbatchNumber(e.target.value)} /></div>
                  <div>  <label>Mac Address :</label><input required value={MacAddress} onChange={(e) => setMacAddress(e.target.value)} /></div>
                  <div>  <label>Serial Number :</label><input required value={serialNumber} onChange={(e) => setserialNumber(e.target.value)} /></div>
                  <div>  <label>IMEI :</label><input required value={IMEI} onChange={(e) => setIMEI(e.target.value)} /></div>
                  <div>  <label>Server IP :</label><input required value={serverIP} onChange={(e) => setserverIP(e.target.value)} /></div>





                  {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                  </> : <>
                    <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                      e.preventDefault()
                      message.error("Please Wait")
                    }}>Please Wait</button>
                  </>}
                  <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

                </form>
              </div>
            </div>
          </>}


          {popup2 === true && <>
            <div className='popup'>
              <div className='popupbg' onClick={() => {
                setpopup2sub("1")
                setpopup2(false)
              }}></div>

              {popup2sub === "1" && <>
                <div className='popupcontant'>
                  <h2>Select The Customer to Assign This device </h2>
                  <div className='customerassignbox'>


                    {staffprofiles.map((value) => <>
                      <div className='customerassigncard' onClick={() => {
                        setpopup2sub("2")
                        setcustomer(value)
                      }}>
                        <h3>Name : {value.FullName}</h3>
                        <h3>Email : {value.email}</h3>
                        <h3>ID Number : {value.IDNumber}</h3>
                      </div>
                    </>)}
                  </div>

                  <button className='formalbtncncl' onClick={() => {
                    setpopup2sub("1")
                    setpopup2(false)
                  }}>Cencel</button>
                </div>
              </>}


              {popup2sub === "2" && <>
                <div className='popupcontant'>
                  <h2>Select The Package for this Purchase</h2>
                  <div className='customerassignbox'>

                    <table className='data-plans-table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>12 months</th>
                          <th>6 months</th>
                          <th>3 months</th>
                          <th>1 month</th>
                          {/* Add more data plan columns */}
                        </tr>
                      </thead>
                      <tbody>

                        <tr >
                          <th>10GB Data</th>
                          <td
                            className={selectedCell === '10GB Data for 12 month in GHc90' ? 'selected-cell' : ''}
                            onClick={async () => {
                              await setSelectedCell('10GB Data for 12 month in GHc90');

                              // await setproductselected({ ...productselected, selectedCell })

                            }

                            }
                          >GHc90</td>
                          <td
                            className={selectedCell === '10GB Data for 6 month in GHc100' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('10GB Data for 6 month in GHc100')}
                          >GHc100</td>
                          <td
                            className={selectedCell === '10GB Data for 3 month in GHc110' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('10GB Data for 3 month in GHc110')}
                          >GHc110</td>
                          <td
                            className={selectedCell === '10GB Data for 1 month in GHc120' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('10GB Data for 1 month in GHc120')}
                          >GHc120</td>

                        </tr>


                        <tr >
                          <th>15GB Data</th>
                          <td
                            className={selectedCell === '15GB Data for 12 month in GHc120' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('15GB Data for 12 month in GHc120')}
                          >GHc120</td>
                          <td
                            className={selectedCell === '15GB Data for 6 month in GHc130' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('15GB Data for 6 month in GHc130')}
                          >GHc130</td>
                          <td
                            className={selectedCell === '15GB Data for 3 month in GHc140' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('15GB Data for 3 month in GHc140')}
                          >GHc140</td>
                          <td
                            className={selectedCell === '15GB Data for 1 month in GHc150' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('15GB Data for 1 month in GHc150')}
                          >GHc150</td>
                          {/* Add more data plan cells */}
                        </tr>


                        <tr >
                          <th>20GB Data</th>
                          <td
                            className={selectedCell === '20GB Data for 12 month in GHc140' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('20GB Data for 12 month in GHc140')}
                          >GHc140</td>
                          <td
                            className={selectedCell === '20GB Data for 6 month in GHc145' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('20GB Data for 6 month in GHc145')}
                          >GHc145</td>
                          <td
                            className={selectedCell === '20GB Data for 3 month in GHc155' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('20GB Data for 3 month in GHc155')}
                          >GHc155</td>
                          <td
                            className={selectedCell === '20GB Data for 1 month in GHc160' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('20GB Data for 1 month in GHc160')}
                          >GHc160</td>
                          {/* Add more data plan cells */}
                        </tr>



                        <tr >
                          <th>35GB Data</th>
                          <td
                            className={selectedCell === '35GB Data for 12 month in GHc170' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('35GB Data for 12 month in GHc170')}
                          >GHc170</td>
                          <td
                            className={selectedCell === '35GB Data for 6 month in GHc185' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('35GB Data for 6 month in GHc185')}
                          >GHc185</td>
                          <td
                            className={selectedCell === '35GB Data for 3 month in GHc200' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('35GB Data for 3 month in GHc200')}
                          >GHc200</td>
                          <td
                            className={selectedCell === '35GB Data for 1 month in GHc220' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('35GB Data for 1 month in GHc220')}
                          >GHc220</td>
                          {/* Add more data plan cells */}
                        </tr>

                        <tr >
                          <th>50GB Data</th>
                          <td
                            className={selectedCell === '50GB Data for 12 month in GHc200' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('50GB Data for 12 month in GHc200')}
                          >GHc200</td>
                          <td
                            className={selectedCell === '50GB Data for 6 month in GHc215' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('50GB Data for 6 month in GHc215')}
                          >GHc215</td>
                          <td
                            className={selectedCell === '50GB Data for 3 month in GHc230' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('50GB Data for 3 month in GHc230')}
                          >GHc230</td>
                          <td
                            className={selectedCell === '50GB Data for 1 month in GHc250' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('50GB Data for 1 month in GHc250')}
                          >GHc250</td>
                          {/* Add more data plan cells */}
                        </tr>


                        <tr >
                          <th>75GB Data</th>
                          <td
                            className={selectedCell === '75GB Data for 12 month in GHc250' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('75GB Data for 12 month in GHc250')}
                          >GHc250</td>
                          <td
                            className={selectedCell === '75GB Data for 6 month in GHc260' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('75GB Data for 6 month in GHc260')}
                          >GHc260</td>
                          <td
                            className={selectedCell === '75GB Data for 3 month in GHc275' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('75GB Data for 3 month in GHc275')}
                          >GHc275</td>
                          <td
                            className={selectedCell === '75GB Data for 1 month in GHc290' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('75GB Data for 1 month in GHc290')}
                          >GHc290</td>
                          {/* Add more data plan cells */}
                        </tr>


                        <tr >
                          <th>100GB Data</th>
                          <td
                            className={selectedCell === '100GB Data for 12 month in GHc270' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('100GB Data for 12 month in GHc270')}
                          >GHc270</td>
                          <td
                            className={selectedCell === '100GB Data for 6 month in GHc280' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('100GB Data for 6 month in GHc280')}
                          >GHc280</td>
                          <td
                            className={selectedCell === '100GB Data for 3 month in GHc290' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('100GB Data for 3 month in GHc290')}
                          >GHc290</td>
                          <td
                            className={selectedCell === '100GB Data for 1 month in GHc300' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('100GB Data for 1 month in GHc300')}
                          >GHc300</td>
                          {/* Add more data plan cells */}
                        </tr>

                        <tr >
                          <th>220GB Data</th>
                          <td
                            className={selectedCell === '220GB Data for 12 month in GHc450' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('220GB Data for 12 month in GHc450')}
                          >GHc450</td>
                          <td
                            className={selectedCell === '220GB Data for 6 month in GHc465' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('220GB Data for 6 month in GHc465')}
                          >GHc465</td>
                          <td
                            className={selectedCell === '220GB Data for 3 month in GHc480' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('220GB Data for 3 month in GHc480')}
                          >GHc480</td>
                          <td
                            className={selectedCell === '220GB Data for 1 month in GHc499' ? 'selected-cell' : ''}
                            onClick={() => setSelectedCell('220GB Data for 1 month in GHc499')}
                          >GHc499</td>
                          {/* Add more data plan cells */}
                        </tr>







                      </tbody>
                    </table>

                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>   <button className='formalbtncncl' onClick={() => {
                    setpopup2sub("1")
                    setpopup2(false)
                  }}>Cencel</button>
                    <button className='formalbtn' onClick={() => setpopup2sub("3")}>Continue</button>
                  </div>
                </div>
              </>}


              {popup2sub === "3" && <>
                <div className='popupcontant'>
                  <h2>Enter Sim the Details Below</h2>
                  <form onSubmit={
                    (e) => {
                      e.preventDefault()
                      setpopup2sub("4")

                    }
                  } className='newenteryform'>




                    <div>  <label>Sim ID :</label><input value={SimID} onChange={(e) => setSimID(e.target.value)} /></div>
                    <div>  <label>Sim Number :</label><input value={SimNumber} onChange={(e) => setSimNumber(e.target.value)} /></div>
                    <div>  <label>Sim Status :</label><input value={SimStatus} onChange={(e) => setSimStatus(e.target.value)} /></div>






                    {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                    </> : <>
                      <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                        e.preventDefault()
                        message.error("Please Wait")
                      }}>Please Wait</button>
                    </>}
                    <button className='formalbtncncl' onClick={() => {
                      setpopup2sub("1")
                      setpopup2(false)
                    }}>Cencel</button>

                  </form>
                </div>
              </>}








              {popup2sub === "4" && <>
                <div className='popupcontant'>
                  <h2>Enter The Prices</h2>
                  <form onSubmit={submitdata2} className='newenteryform'>
                    <div className='det' style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

                      <h4 >Device Details</h4>
                      <p style={{ margin: '0' }}>Device Type: {DeviceID.deviceType}</p>
                      <p style={{ margin: '0' }}>Device IMEI : {DeviceID.IMEI}</p>
                      <p style={{ margin: '0' }}>Device manufacturer : {DeviceID.manufacturer}</p>


                      <h4 style={{ marginTop: '10px' }}>Device Details</h4>
                      <p style={{ margin: '0' }}>Customer Name: {customer.FullName}</p>
                      <p style={{ margin: '0' }}>Customer Email : {customer.email}</p>
                      <p style={{ margin: '0' }}>Customer ID :{customer.IDNumber}</p>

                      <h4 style={{ marginTop: '10px' }}>Selected Package</h4>
                      <p style={{ margin: '0' }}>{selectedCell}</p>

                      <h4 style={{ marginTop: '10px' }}>Sim Details</h4>
                      <p style={{ margin: '0' }}>{SimID}</p>


                    </div>
                    <div >   <label>Device Price :</label><input value={DevicePrice} onChange={(e) => setDevicePrice(e.target.value)} /></div>
                    <div>  <label>Package Price :</label><input value={PackagePrice} onChange={(e) => setPackagePrice(e.target.value)} /></div>
                    <div>  <label>Sim Price :</label><input value={SimPrice} onChange={(e) => setSimPrice(e.target.value)} /></div>
                    <div>  <label>Taxes :</label><input value={Taxes} onChange={(e) => setTaxes(e.target.value)} /></div>
                    <div>  <label>Other :</label><input value={Other} onChange={(e) => setOther(e.target.value)} /></div>






                    {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                    </> : <>
                      <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                        e.preventDefault()
                        message.error("Please Wait")
                      }}>Please Wait</button>
                    </>}
                    <button className='formalbtncncl' onClick={() => {
                      setpopup2sub("1")
                      setpopup2(false)
                    }}>Cencel</button>

                  </form>
                </div>
              </>}


            </div>
          </>}


        </div>
      </>}  </div>
  )
}

export default Inventory