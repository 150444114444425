import React from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


function FinancialDetails() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")
  const Navigate = useNavigate()

  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>


        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>
            <h1>Financial Details</h1>

            <div className='dashbordboxcantainer'>


              <div className='dashboardbox' onClick={() => Navigate("/InvoicesPayments")} style={{ backgroundColor: '#E12330' }}>

                <h2>Invoices</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/")} style={{ backgroundColor: '#26A1AB' }}>

                <h2>Payments</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Creditnotes")} style={{ backgroundColor: '#703688' }}>

                <h2>Credit notes</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Commissions")} style={{ backgroundColor: '#C09900' }}>

                <h2>Commissions</h2>
              </div>

              <div className='dashboardbox' onClick={() => Navigate("/Expenses")} style={{ backgroundColor: '#CBD5DA' }}>

                <h2>Expenses</h2>
              </div>


            </div>
          </div>
        </div>
      </>}  </div>
  )
}

export default FinancialDetails