import React, { useState, useEffect } from 'react'
import Navbar from '../components/navbar'
import Sidemanu from '../components/sidemanu'
import Cookies from 'js-cookie';
import "./Css/InvoicesPayments.css"



function Subscriptions() {
  const id = Cookies.get("id")
  const token = Cookies.get("Token")
  const loginemail = Cookies.get("email")
  const [popup, setpopup] = useState(false)

  const [invoice, setinvoice] = useState([])
  const [device, setdevice] = useState([])
  const [staffprofiles, setstaffprofiles] = useState([])

  useEffect(() => {




    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getinvoice", requestOptions)
      .then(response => response.json())
      .then(result => setinvoice(result))
      .catch(error => console.log('error', error));





    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getdevices", requestOptions)
      .then(response => response.json())
      .then(result => setdevice(result))
      .catch(error => console.log('error', error));



    var requestOptions = {
      method: 'GET',

      redirect: 'follow'
    };

    fetch("https://backend.datawarehousegh.com:1337/getCustomerprofiles", requestOptions)
      .then(response => response.json())
      .then(result => setstaffprofiles(result))
      .catch(error => console.log('error', error));












  }, [])




  return (


    <div>
      {token === "dskgfsdgfkgsdfkjg35464154845674987dsf@53" && <>

        <Navbar />
        <div className='mainbody'>
          <Sidemanu />
          <div className='dashbordbody'>


            <div className='header'>
              <h1 className='pppheading'> Subription</h1>


            </div>
            <div className='Tableheader'><h3>******* </h3><button onClick={() => setpopup(true)} className='formalbtn'>New Entery</button></div>


            <div style={{ marginTop: '50px' }}></div>

            {invoice.map((value) => {

              var CustomerName
              var Email
              var ID
              var Address


              var deviceType
              var manufacturer
              var IMEI
              var SimNumber

              staffprofiles.map((value2) => {

                if (value2._id === value.CustomerID) {

                  CustomerName = value2.FullName
                  Email = value2.email
                  ID = value2.IDNumber
                  Address = value2.Address

                }

              })

              device.map((value3) => {

                if (value3._id === value.DeviceID) {

                  deviceType = value3.deviceType
                  manufacturer = value3.manufacturer
                  IMEI = value3.IMEI
                  SimNumber = value3.SimNumber

                }

              })


              return (<>
                <div>
                  <div className='invoicebox'>
                    <div className='invoiceinside'>
                      <div>
                        <h3>{CustomerName}</h3>
                        <p>{Email}</p>
                        <p>{ID}</p>
                        <p>{Address}</p>
                        <p>{Email}</p>
                      </div>


                      <div>

                        <h3>{deviceType}</h3>
                        <p>{manufacturer}</p>
                        <p>{IMEI}</p>
                        <p>{SimNumber}</p>

                      </div>

                    
                    </div>
                    <div>
                      <h3>{value.Package}</h3>
                    </div>

                    <div>
                      <h3>Started on : {value.timestamp}</h3>
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <button className='formalbtncncl'>Delete</button>
                      <button className='formalbtn'>Renew</button>

                    </div>
                  </div>

                </div>
              </>)
            })}
          </div>


          {/* {popup === true && <>
          <div className='popup'>
            <div className='popupbg' onClick={() => setpopup(false)}></div>
            <div className='popupcontant'>
              <h2>Enter the Details Below</h2>
              <form onSubmit={submitdata} className='newenteryform'>

                <div>  <label>Full Name :</label><input required value={FullName} onChange={(e) => setFullName(e.target.value)} /></div>
                <div><label>Date Of Birth :</label><input required value={DateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} /></div>
                <div><label>Address :</label><input required value={Address} onChange={(e) => setAddress(e.target.value)} /></div>
                <div><label>Contact Details :</label><input required value={ContactDetails} onChange={(e) => setContactDetails(e.target.value)} /></div>
                <div><label>Next Of Kin Name and Contact Number :</label><input required value={NextOfKinNameandContactNumber} onChange={(e) => setNextOfKinNameandContactNumber(e.target.value)} /></div>
                <div><label>Socail Security Number :</label><input required value={SocailSecurityNumber} onChange={(e) => setSocailSecurityNumber(e.target.value)} /></div>
                <div><label>ID Type :</label>

                  <select id="id" name="id" onChange={(e) => setIDType(e.target.value)}>
                    <option disabled selected> select option</option>
                    <option value="Passport">Passport</option>
                    <option value="Ecowas Card">Ecowas Card</option>
                    <option value="Voters Card">Voters Card</option>

                  </select>
                </div>


                <div><label>ID Number :</label><input required value={IDNumber} onChange={(e) => setIDNumber(e.target.value)} /></div>
                <div><label>Emergancy Contact Number And Name  :</label><input required value={EmergancyContactNumberAndName} onChange={(e) => setEmergancyContactNumberAndName(e.target.value)} /></div>

                <h2>Job Imformation</h2>
                <div><label>Job Title :</label><input required value={Title} onChange={(e) => setTitle(e.target.value)} /></div>
                <div><label>Department :</label><input required value={Department} onChange={(e) => setDepartment(e.target.value)} /></div>
                <div><label>Core Responsibilities :</label><input required /></div>

                <div><label>Supervisor :</label><input required value={Supervisor} onChange={(e) => setSupervisor(e.target.value)} /></div>
                <div><label>Work Location :</label><input required value={WorkLocation} onChange={(e) => setWorkLocation(e.target.value)} /></div>
                <div><label>Starting Date :</label><input required value={StartingDate} onChange={(e) => setStartingDate(e.target.value)} /></div>
                <div><label>Employment Type :</label>

                  <select id="type" name="type" onChange={(e) => setEmploymentType(e.target.value)}>
                    <option disabled selected> select option</option>
                    <option value="Full Time">Full Time</option>
                    <option value="Part Time">Part Time</option>
                    <option value="Commission Only">Commission Only</option>

                  </select>
                </div>
                <div><label>Condition :</label>

                  <select id="Condition" name="Condition" onChange={(e) => setCondition(e.target.value)}>
                    <option disabled selected> select option</option>
                    <option value="Probation">Probation</option>
                    <option value="Extended Probation">Extended Probation </option>
                    <option value="Confirmed">Confirmed</option>

                  </select>
                </div>
                <div><label>Gross Salary :</label><input required value={Salary} onChange={(e) => setSalary(e.target.value)} /></div>

                <h2>Socail Media Links</h2>
                <div><label>Facebook Link :</label><input required value={FacebookLink} onChange={(e) => setFacebookLink(e.target.value)} /></div>
                <div><label>Twitter(X) Link :</label><input required value={TwitterLink} onChange={(e) => setTwitterLink(e.target.value)} /></div>
                <div><label>Tiktiok Link :</label><input required value={TiktiokLink} onChange={(e) => setTiktiokLink(e.target.value)} /></div>
                <div><label>Instagram Link :</label><input required value={InstagramLink} onChange={(e) => setInstagramLink(e.target.value)} /></div>
                <div><label>youtube Link :</label><input required value={youtubeLink} onChange={(e) => setyoutubeLink(e.target.value)} /></div>

                <h2>Performance Appraisal Related Notes</h2>
                <div><textarea className='textarea' required style={{ width: "100%", height: '100px' }} value={PerformanceAppraisalRelatedNotes} onChange={(e) => setPerformanceAppraisalRelatedNotes(e.target.value)} /></div>
                <div><label>Offical's Name :</label><input required value={OfficalName} onChange={(e) => setOfficalName(e.target.value)} /></div>
                <div><label>Offical's Position :</label><input required value={OfficalPosition} onChange={(e) => setOfficalPosition(e.target.value)} /></div>
                <div><label>Appraisal's Date :</label><input required value={AppraisalDate} onChange={(e) => setAppraisalDate(e.target.value)} /></div>


                <h2>login credentials</h2>
                <div><label>Email :</label><input required value={email} onChange={(e) => setemail(e.target.value)} /></div>
                <div><label>Passpord :</label><input type='password' required value={password} onChange={(e) => setpassword(e.target.value)} /></div>



                {btn ? <>  <button className='formalbtn' type='submit'>Submit</button>
                </> : <>
                  <button className='formalbtn' style={{ backgroundColor: "gray" }} onClick={(e) => {
                    e.preventDefault()
                    message.error("Please Wait")
                  }}>Please Wait</button>
                </>}
                <button className='formalbtncncl' onClick={() => setpopup(false)}>Cencel</button>

              </form>
            </div>
          </div>
        </>} */}

        </div>
      </>}  </div>
  )
}

export default Subscriptions